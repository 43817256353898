import { useCallback, useEffect, useState } from 'react';

import { Drawer, useMantineTheme, Text, Flex, Button, Box, Loader } from '@mantine/core';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { isNil } from 'helpers/isNotNil';
import { extractTextFromHTML } from 'helpers/stringFunctions';

import { useMessages } from './MessageCenter/MessagesContext';
import { MessageDetails } from './MessageCenter/types';

export const HighPriorityMessageBanner = () => {
  const [highPriorityMessage, setHighPriorityMessage] = useState<MessageDetails | null>(null);
  const [currentlyViewing, setCurrentlyViewing] = useState(0);
  const {
    updating,
    dismissAllHighPriority,
    dismissingAll,
    undismissedHighPriorityMessages,
    setUndismissedHighPriorityMessages,
    getMessageDetails,
    gettingMessageDetails,
    updateMessage,
  } = useMessages();
  const theme = useMantineTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if (undismissedHighPriorityMessages.length > 0) {
      getMessageDetails(undismissedHighPriorityMessages[currentlyViewing].messageId).then(setHighPriorityMessage);
    }
  }, [getMessageDetails, undismissedHighPriorityMessages, currentlyViewing]);

  const dimissMessage = useCallback(() => {
    if (isNil(highPriorityMessage)) return;
    updateMessage(highPriorityMessage.messageId, 'Dismiss').then(() => {
      if (currentlyViewing < undismissedHighPriorityMessages.length - 1) {
        setCurrentlyViewing((prev) => prev + 1);
      } else {
        setUndismissedHighPriorityMessages([]);
      }
    });
  }, [
    currentlyViewing,
    highPriorityMessage,
    setUndismissedHighPriorityMessages,
    undismissedHighPriorityMessages,
    updateMessage,
  ]);

  const doDismissAll = useCallback(() => {
    dismissAllHighPriority().then(() => {
      setUndismissedHighPriorityMessages([]);
    });
  }, [dismissAllHighPriority, setUndismissedHighPriorityMessages]);

  return (
    <Drawer
      position="bottom"
      opened={undismissedHighPriorityMessages.length > currentlyViewing}
      onClose={dimissMessage}
      size={300}
      closeOnClickOutside={false}
      color="red"
      overlayProps={{ color: theme.primaryColor }}
    >
      <Box px={20} py={0} h="100%">
        {highPriorityMessage && (
          <>
            <Flex justify="space-between">
              <Text size="xl" weight="bold">
                {highPriorityMessage.subject}
              </Text>
              {undismissedHighPriorityMessages.length > 1 && (
                <Text>
                  {currentlyViewing + 1} of {undismissedHighPriorityMessages.length}
                </Text>
              )}
            </Flex>
            <Text size="sm" color={theme.primaryColor} mb="md">
              {format(parseISO(`${highPriorityMessage?.createdOn}Z`), 'MMMM dd, yyyy')}
            </Text>
          </>
        )}
        {updating || gettingMessageDetails ? (
          <Loader size="md" />
        ) : (
          <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} w="100%" mb={50}>
            {extractTextFromHTML(highPriorityMessage?.body).replace('&apos;', "'")}
          </Text>
        )}
        <Flex justify="flex-end" gap="sm" align="center">
          <Button variant="outline" onClick={dimissMessage} loading={updating} disabled={gettingMessageDetails}>
            Dismiss
          </Button>
          {undismissedHighPriorityMessages.length > 1 && (
            <Button
              variant="outline"
              onClick={doDismissAll}
              loading={dismissingAll}
              disabled={updating || gettingMessageDetails}
            >
              Dismiss All
            </Button>
          )}
          <Button
            onClick={() => {
              dimissMessage();
              navigate(`/message/${highPriorityMessage?.messageId}`);
            }}
            disabled={updating || gettingMessageDetails}
          >
            View in message center
          </Button>
        </Flex>
      </Box>
    </Drawer>
  );
};
