import { useEffect } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';

import { HighPriorityMessageBanner } from './HighPriorityMessageBanner';
import { Layout } from './Layout/Layout';
import { MessagesProvider } from './MessageCenter/MessagesContext';

export const PrivateWrapper = () => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (isNotNil(user)) {
      // @ts-expect-error function is defined globally
      window.initializePendo(user);
    }
  }, [user]);

  if (loading) return <LoadingOverlay visible />;

  if (!user) return <Navigate to="/auth/login" />;

  return (
    <>
      <MessagesProvider>
        <HighPriorityMessageBanner />
        <Layout />
      </MessagesProvider>
    </>
  );
};
