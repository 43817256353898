import type { BoxProps } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { TaskStatusTypeName } from 'constants/badgeMappingStatus';

export const TaskPlayPauseIcon = ({
  taskStatusTypeName,
  boxProps,
}: {
  taskStatusTypeName: TaskStatusTypeName;
  boxProps?: BoxProps;
}) =>
  taskStatusTypeName === 'Started' || taskStatusTypeName === 'Paused' ? (
    <AgGridStyleTooltip
      openDelay={100}
      label={taskStatusTypeName === 'Started' ? 'Task is running' : 'Task is paused'}
      withArrow
    >
      <EvolveIcon
        boxProps={boxProps}
        icon={taskStatusTypeName === 'Started' ? 'Play' : 'Pause'}
        size="sm"
        color="inherit"
        inline
      />
    </AgGridStyleTooltip>
  ) : null;
