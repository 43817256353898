import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { createBrowserHistory } from 'history';
// @ts-ignore FIXME :)
// FIXME: see https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-typescript-definitions
import ReactDOM from 'react-dom';

import getClient from 'apollo/client';
import { App } from 'app/App';
import { ErrorBoundary } from 'app/ErrorBoundary';
import { ModuleProvider } from 'app/Modules/ModuleContext';
import { UserProvider } from 'app/UserContext';
import { MantineNotificationsProvider } from 'components/Mantine/useMantineNotifications';

import { configureAmplify } from './amplifyconfig';
import { BrowserRouter } from './BrowserRouter';

import './scripts/autodesk';

const history = createBrowserHistory();

LicenseInfo.setLicenseKey(
  '6e2ac62351d84986f7a792ce57b81a1aTz05MzU2OSxFPTE3NTE1NTM0NzIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const AppWithProvider = ({ client }: { client: ApolloClient<NormalizedCacheObject> }) => (
  <MantineNotificationsProvider>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <UserProvider>
          <BrowserRouter history={history}>
            <ModuleProvider>
              <App />
            </ModuleProvider>
          </BrowserRouter>
        </UserProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </MantineNotificationsProvider>
);

configureAmplify()
  .then(() => {
    getClient().then((client) => {
      ReactDOM.render(<AppWithProvider client={client} />, document.getElementById('root'));
    });
  })
  .catch();
