import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';

import { Customers } from './customers/Customers';
import { EvolveMembersPage } from './evolveMembers/EvolveMembersPage';

export const EvolveMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <EvolveMembersPage />
  </ComponentPaneLayout>
);

export const CustomersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Customers />
  </ComponentPaneLayout>
);
