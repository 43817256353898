import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ProjectRequired } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';

import { CompanyMembersPage } from './CompanyMembers/CompanyMembersMantine/CompanyMembersPage';
import { CompanyPropertiesPage } from './CompanyProperties/CompanyPropertiesMantine/CompanyPropertiesPage';
import { FacilitiesPage } from './FacilitiesMantine/FacilitiesPage';
import { LicensesPage } from './Licenses/LicensesMantine/LicensesPage';
import { ProjectMembersPage } from './ProjectMembers/ProjectMembersMantine/ProjectMembersPage';
import { ProjectPropertiesPage } from './ProjectProperties/ProjectPropertiesPage';
import { ProjectsPage } from './Projects/ProjectsMantine/ProjectsPage';

export const ProjectInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <ProjectsPage />
  </ComponentPaneLayout>
);

export const FacilitiesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <FacilitiesPage />
  </ComponentPaneLayout>
);

export const CompanyMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <CompanyMembersPage />
  </ComponentPaneLayout>
);

export const ProjectMembersInterfaceNavContent = () => (
  <ProjectRequired>
    {(selectedProject) => (
      <ComponentPaneLayout>
        <ProjectMembersPage selectedProject={selectedProject} />
      </ComponentPaneLayout>
    )}
  </ProjectRequired>
);

export const CompanyPropertiesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <CompanyPropertiesPage />
  </ComponentPaneLayout>
);

export const ProjectPropertiesInterfaceNavContent = () => (
  <ProjectRequired>
    {(selectedProject) => (
      <ComponentPaneLayout>
        <ProjectPropertiesPage selectedProject={selectedProject} />
      </ComponentPaneLayout>
    )}
  </ProjectRequired>
);

export const LicensesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <LicensesPage />
  </ComponentPaneLayout>
);
