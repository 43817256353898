/* eslint-disable no-console */
import { useApolloClient } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';

import { doSignOut } from './signOutHelper';

const useSignOut = () => {
  const client = useApolloClient();
  const { setUser } = useUser();
  const { clearSelectedItem } = useSelectedProjectFacility();
  const { resetModule } = useSetupModule();

  const { apiCall } = useWrappedPost('admin/authentication/logout');

  const signOut = async () => {
    client.stop();
    await client?.clearStore().catch(console.error);
    await apiCall({})
      .catch(console.error)
      .finally(() => {
        resetModule();
        clearSelectedItem();
        setUser(null);
        doSignOut();
      });
  };

  return signOut;
};

export default useSignOut;
