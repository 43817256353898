import { useEffect, useState } from 'react';

import { Badge, Button, Card, Flex, Loader, Select, Text, useMantineTheme } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useDebouncedValue } from '@mantine/hooks';
import { endOfMonth, endOfWeek, format, isSameDay, parseISO, startOfMonth, startOfWeek } from 'date-fns';

import { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { workOrderStatusTypeNames } from 'constants/badgeMappingStatus';
import { convertDateToStartOrEndOfDay } from 'helpers/ag-grid/convertAgGridRequest';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { Project, ProjectId } from 'hooks/projectsAndFacilities/useProjects';

import { calendarStyles, highlightDayStyles } from './calendarStyles';
import { ShopKanbanCard } from './KanbanCard';
import { WorkOrder } from './types';
import { useWorkOrders } from './useWorkOrders';

type Props = {
  selectedFacility: SelectedFacility;
};

export const WorkOrdersCalendarView = ({ selectedFacility }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<Date>(selectedDate);
  const [projectFilterValue, setProjectFilterValue] = useState<ProjectId | null>(null);
  const [workOrders, setWorkOrders] = useState<WorkOrder[] | null>(null);
  const [loadingWorkOrders, setLoadingWorkOrders] = useState(false);

  const [debouncedMonth] = useDebouncedValue(selectedMonth, 750);

  const { fetchWorkOrders } = useWorkOrders();

  const theme = useMantineTheme();

  const { data: projects } = useWrappedGet<Project[]>('shop/project/projectsForActiveWorkOrders', {
    defaultConfig: {
      params: {
        facilityId: selectedFacility.id,
      },
    },
  });

  useEffect(() => {
    setLoadingWorkOrders(true);
    getAllDataFromFetcher(fetchWorkOrders, {
      params: {
        projectIds: projectFilterValue ?? undefined,
        needBy: `start:${convertDateToStartOrEndOfDay(
          startOfWeek(startOfMonth(debouncedMonth)),
          '00:00:00',
        )},end:${convertDateToStartOrEndOfDay(endOfWeek(endOfMonth(debouncedMonth)), '23:59:59')}`,
      },
    })
      .then((res) => {
        setWorkOrders(
          res
            .sort((a, b) =>
              workOrderStatusTypeNames.indexOf(a.workOrderStatusTypeName) >
              workOrderStatusTypeNames.indexOf(b.workOrderStatusTypeName)
                ? 1
                : -1,
            )
            .sort((a, b) => a.workRequest.projectName.localeCompare(b.workRequest.projectName)),
        );
      })
      .finally(() => setLoadingWorkOrders(false));
  }, [debouncedMonth, fetchWorkOrders, projectFilterValue]);

  return (
    <>
      <BasePageHeader
        title="Work Orders"
        viewsToShow={['list', 'kanban', 'calendar']}
        topLeftComponent={(loadingWorkOrders || selectedMonth !== debouncedMonth) && <Loader />}
        topRightComponent={
          <Select
            searchable
            clearable
            data={projects?.map((p) => ({ label: p.projectName, value: p.projectId })) ?? []}
            onChange={(value: ProjectId) => setProjectFilterValue(value)}
            value={projectFilterValue}
            w={250}
            placeholder="Project"
          />
        }
      />
      <Flex h="100%" style={{ overflow: 'hidden' }} gap="sm">
        <Card miw={350} h="100%" p={0} withBorder style={{ overflowY: 'auto' }}>
          <Flex justify="space-between" mb="lg" bg="gray.1" p="md">
            <Badge h={50} w={50} radius={25} bg={`${theme.primaryColor}.1`} fz={18}>
              {format(selectedDate, 'd')}
            </Badge>
            <div>
              <Text size="xl" weight="bold">
                {format(selectedDate, 'cccc')}
              </Text>
              <Text>{format(selectedDate, 'PPP')}</Text>
            </div>
            <Button
              variant="outline"
              onClick={() => {
                setSelectedDate(new Date());
                setSelectedMonth(new Date());
              }}
            >
              Today
            </Button>
          </Flex>
          <Flex direction="column" gap="xs" p="xs" w="100%">
            {workOrders
              ?.filter((wo) => isSameDay(parseISO(`${wo.workRequest.needBy}Z`), selectedDate))
              .map((wo) => (
                <ShopKanbanCard
                  currentPage="Work Orders"
                  cardDestination={wo.workOrderId}
                  {...wo.workRequest}
                  {...wo}
                  status={wo.workOrderStatusTypeName}
                  includeStatusBadge
                  cardProps={{
                    style: {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <EvolveLink className="kanban-work-order-link" to={`${wo.workOrderId}`} from="Work Orders">
                    <Text fw={500} display="inline" lineClamp={3} title={wo.workRequest.workRequestName}>
                      {wo.workRequest.workRequestName}
                    </Text>
                  </EvolveLink>
                </ShopKanbanCard>
              ))}
          </Flex>
        </Card>
        <Calendar
          onDateChange={setSelectedMonth}
          date={selectedMonth}
          styles={calendarStyles}
          renderDay={(date) => {
            const day = format(date, 'dd');
            return (
              <Flex
                key={date.toISOString()}
                onClick={() => setSelectedDate(date)}
                direction="column"
                style={{
                  flex: 1,
                  overflow: 'hidden',
                  width: '100%',
                  ...(isSameDay(selectedDate, date) ? highlightDayStyles : {}),
                }}
              >
                <Text w="100%" bg="gray.1" align="center" color="black" style={{ position: 'sticky', top: 0 }}>
                  {day === '01' ? `${format(date, 'LLL')} 01` : day}
                </Text>
                <Flex mt={4} direction="column" style={{ flex: '1 1 auto', overflowY: 'auto', width: '100%' }}>
                  <Flex direction="column" gap={3} style={{ flex: '1 0 auto' }}>
                    {projects?.map((p) => {
                      const filtered = workOrders?.filter(
                        (wo) =>
                          wo.workRequest.projectId === p.projectId &&
                          isSameDay(parseISO(`${wo.workRequest.needBy}Z`), date),
                      );
                      if (isNotNil(filtered) && filtered.length > 0)
                        return (
                          <Badge
                            size="lg"
                            w="100%"
                            styles={{
                              root: {
                                justifyContent: 'space-between',
                              },
                              inner: {
                                textTransform: 'capitalize',
                                color: 'black',
                                fontWeight: 'normal',
                              },
                            }}
                            radius={3}
                            rightSection={
                              <Badge size="xs" color="white" style={{ backgroundColor: 'white', alignSelf: 'right' }}>
                                {filtered.length}
                              </Badge>
                            }
                          >
                            {p.projectName}
                          </Badge>
                        );
                      return null;
                    })}
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        />
      </Flex>
    </>
  );
};
