import { useState, useEffect } from 'react';

import { Button, Checkbox, Flex, PasswordInput, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { EULAModal } from 'app/EULAModal';
import { useUser } from 'app/UserContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { setLocalStorage } from 'hooks/useLocalStorage';
import useQuery from 'hooks/useQuery';
import type { UserNameIdSymbol } from 'types/types-api';

import { useConfirmUser } from '../auth/restCalls';
import { useSignIn } from '../auth/useSignIn';
import { AuthFormWrapper } from '../components/AuthFormWrapper';
import { isLoginErrorCode, LoginErrorCode } from '../components/LoginErrorAlert';

const { EVOLVE_TOKEN_USERNAME, EVOLVE_REFRESH_TOKEN, EVOLVE_ACCESS_TOKEN } = LOCAL_STORAGE_CONSTANTS;

export const Login = () => {
  const { confirm } = useConfirmUser();
  const { refreshUser } = useUser();
  const { signIn, checkForLatestEulaAgreement } = useSignIn();
  const [signingIn, setSigningIn] = useState(false);

  const [EulaUser, setEulaUser] = useState<UserNameIdSymbol>();
  const [errorCode, setErrorCode] = useState<LoginErrorCode | 'UnknownException'>();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const query = useQuery();
  const queryErrorCode = query.get('errorCode') as LoginErrorCode | null;
  const username = query.get('username');
  const code = query.get('code');
  useEffect(() => setErrorCode((c) => queryErrorCode ?? c), [queryErrorCode]);

  const form = useForm<{
    email: string;
    password: string;
    keepEnabled: boolean;
  }>({
    initialValues: {
      email: '',
      password: '',
      keepEnabled: false,
    },
  });

  // From src/modules/Authentication/ResetPassword.tsx
  const changePasswordSuccess = state?.resetPassword;
  // From src/modules/Authentication/Join/Join.tsx
  const confirmationSuccess = state?.inviteConfirm;

  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    if (isConfirmed) {
      setSuccessMessage('Your email address has been verified!');
    }
  }, [isConfirmed]);
  useEffect(() => {
    if (changePasswordSuccess) {
      setSuccessMessage('Success! Your new password has been set.');
    }
  }, [changePasswordSuccess]);
  useEffect(() => {
    if (confirmationSuccess) {
      setSuccessMessage('Success! You have successfully joined.');
    }
  }, [confirmationSuccess]);

  useEffect(() => {
    if (!isConfirmed && username && code) {
      confirm(username, 'company', { confirmationCode: code })
        .then((response) => {
          if (response === 'SUCCESS') {
            setIsConfirmed(true);
          }
        })
        .catch(() => setIsConfirmed(false));
    }
  }, [code, confirm, isConfirmed, username]);

  const login = async (values: typeof form.values) => {
    let navigated = false;
    try {
      const authResult = await signIn(values);
      setLocalStorage(EVOLVE_ACCESS_TOKEN, authResult.accessToken);
      setLocalStorage(EVOLVE_REFRESH_TOKEN, authResult.refreshToken);
      setLocalStorage(EVOLVE_TOKEN_USERNAME, authResult.tokenUserName);
      await refreshUser().then(() => {
        navigated = true;
        navigate('/');
      });
    } catch (error: any) {
      const codeFromError = error.code;
      if (isLoginErrorCode(codeFromError)) {
        if (codeFromError === 'UserNotVerifiedFoundException') {
          navigated = true;
          navigate(`/auth/email-confirmation/?isUnverified=true&email=${values.email}`);
        } else {
          setErrorCode(codeFromError);
        }
      } else {
        setErrorCode('UnknownException');
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } finally {
      if (!navigated) setSigningIn(false);
    }
  };

  const handleSignIn = async (values: typeof form.values) => {
    setErrorCode(undefined);
    setSuccessMessage(undefined);
    setSigningIn(true);
    const { userName, acceptedLatestEula } = await checkForLatestEulaAgreement(values.email);

    if (acceptedLatestEula) {
      await login(values);
    } else if (userName) {
      setEulaUser(userName);
    } else {
      setErrorCode('UserNotFoundException');
      setSigningIn(false);
    }
  };

  return (
    <>
      <AuthFormWrapper
        title="Login"
        form={form}
        onSubmit={handleSignIn}
        errorCode={errorCode}
        onCloseError={() => setErrorCode(undefined)}
        successMessage={successMessage}
        onCloseSuccess={() => setSuccessMessage(undefined)}
      >
        <>
          <TextInput
            label="Email"
            name="email"
            data-cy="email-input"
            size="md"
            autoFocus
            type="email"
            disabled={signingIn}
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            data-cy="password-input"
            name="password"
            size="md"
            disabled={signingIn}
            {...form.getInputProps('password')}
          />
          <Flex my="lg" justify="space-between" align="center">
            <Checkbox
              color="dark"
              size="md"
              label="Keep me signed in"
              disabled={signingIn}
              {...form.getInputProps('keepEnabled')}
            />
            <Link
              className="link"
              data-cy="forgot-password"
              to="/auth/forgot-password"
              state={{ email: form.values.email }}
            >
              <Text c={signingIn ? 'dimmed' : '#4A93FF'}>Forgot Password?</Text>
            </Link>
          </Flex>
          <Button
            type="submit"
            loading={signingIn}
            disabled={!form.values.email || !form.values.password}
            fullWidth
            color="indigo.9"
          >
            Login
          </Button>
        </>
      </AuthFormWrapper>
      <EULAModal
        userName={EulaUser}
        onEulaAccepted={() => {
          setEulaUser(undefined);
          handleSignIn(form.values);
        }}
        onClose={() => {
          setEulaUser(undefined);
          setSigningIn(false);
        }}
      />
    </>
  );
};
