import { useEffect, useMemo, useState } from 'react';

import { ActionIcon, Box, Button, Flex, Loader, Popover, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';
import { format, parseISO } from 'date-fns';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { getCompanyMembersColDef } from 'modules/Admin/CompanyMembers/CompanyMembersMantine/columnDefs';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import type { Company, DownloadPath, User } from 'types/types-api';

const CompanyDocument = ({ downloadPath, user }: Pick<Props, 'user'> & { downloadPath: DownloadPath }) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const { getPresignedUrl, presigningInProgress } = useDocumentsCache();
  const name = downloadPath.document
    .split('/')
    .find((el) => el.includes('.'))
    ?.replace(/\.zip/g, '')
    .split('-')
    .join(' ');
  const download = () => {
    if (presigningInProgress) return;
    setLoading(true);
    getPresignedUrl({
      objectKey: downloadPath.document,
      expirationHours: 24,
      requestedBy: user.userId,
      verb: 'GET',
    })
      .then(({ preSignedURL }) => {
        window.open(preSignedURL, '_blank');
      })
      .finally(() => setLoading(false));
  };
  const disabled = presigningInProgress && !loading;
  return (
    <Box style={{ cursor: 'pointer' }} onClick={download}>
      <Flex gap="xs" align="center">
        <ActionIcon variant="transparent" loading={loading} disabled={disabled} color="primary">
          <EvolveIcon icon="Download" color="inherit" />
        </ActionIcon>
        <Text fz="sm" c={disabled ? 'dimmed' : theme.primaryColor} lh={1.25}>
          {name}
        </Text>
        <Text c="dimmed" fz="xs" lh={1.25}>
          Uploaded {format(parseISO(`${downloadPath.updatedOn}Z`), 'MM/dd/yyyy')}
        </Text>
      </Flex>
    </Box>
  );
};

type Props = {
  user: User;
  company: Company;
};

const modalOptions = ['Remove admin'] as const;

const CompanyPropertiesPageImp = ({ user, company }: Props) => {
  const [searchPhrase, setSearchPhrase] = useDebouncedState('', 400);
  const [modalOpened, setModalOpened] = useState<typeof modalOptions[number] | null>(null);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [saving, setSaving] = useState(false);
  const { apiCall: removeAdmin } = useWrappedPatch<User, { isAdmin: false }>('admin/user/:userId');
  const { fetchPage, searchHandler } = useWrappedPaginatedGet<User>('admin/user', {
    defaultConfig: {
      params: {
        companyId: company.companyId,
        isAdmin: true,
      },
    },
  });
  useEffect(() => {
    searchHandler(searchPhrase);
  }, [searchHandler, searchPhrase]);
  const colDef = useMemo(
    () =>
      getCompanyMembersColDef({
        hasDetailTable: false,
        filterOnStatus: false,
      }),
    [],
  );
  const { agGridProps, refreshGrid, filterIsSet } = useServerSideGrid({
    colDef,
    fetchPage,
    rowId: 'userId',
    tableName: 'company-admins',
  });

  const onConfirmRemoveAdmins = () => {
    setSaving(true);
    Promise.allSettled(
      selectedRows.map(({ userId }) =>
        removeAdmin({ isAdmin: false }, { url: `admin/user/${userId}` }).then(() => {
          notifications.show({
            title: 'Successfully removed admin',
            message: `User${selectedRows.length === 1 ? '' : 's'} removed as admin${
              selectedRows.length === 1 ? '' : 's'
            }.`,
            color: 'green',
          });
        }),
      ),
    ).then(() => {
      setSaving(false);
      setSelectedRows([]);
      setModalOpened(null);
      agGridProps.ref.current?.api.deselectAll();
      refreshGrid();
    });
  };

  return (
    <>
      <BasePageHeader
        title="Company Properties"
        filterIsSet={filterIsSet || !!searchPhrase}
        onFilterClear={() => {
          setSearchPhrase('');
          searchHandler('');
        }}
        gridRef={agGridProps.ref}
        noHistory
        spacing="lg"
        topLeftComponent={
          <Text fz="lg" fw={600} c="gray.7">
            {company.companyName}
          </Text>
        }
        topRightComponent={
          <>
            <Popover position="bottom-end">
              <Popover.Target>
                <Button
                  variant="outline"
                  color="gray"
                  c="dark"
                  leftIcon={<EvolveIcon icon="Download" color="inherit" />}
                >
                  Downloads
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex direction="column" gap="lg">
                  {company.companyDownloadPaths.map((d) => (
                    <CompanyDocument key={d.document} user={user} downloadPath={d} />
                  ))}
                  {company.companyDownloadPaths.length === 0 && (
                    <Text fz="sm" c="dimmed">
                      No downloads found.
                    </Text>
                  )}
                </Flex>
              </Popover.Dropdown>
            </Popover>
            <InviteUsersModal pageType="company" refresh={refreshGrid} companyId={user.companyId} isAdmin />
          </>
        }
        bottomLeftComponent={
          <>
            <Text fw={500}>Company Administrators</Text>
            <WrappedSelect
              disabled={selectedRows.length === 0}
              value={modalOpened}
              placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
              data={modalOptions}
              onChange={setModalOpened}
            />
          </>
        }
        bottomRightComponent={
          <TextInputDebounced
            icon={<EvolveIcon icon="Search" color="inherit" />}
            placeholder="Search..."
            onChange={searchHandler}
            value={searchPhrase}
            onImmediateChange={setSearchPhrase}
          />
        }
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<User>
          {...agGridProps}
          rowSelection="multiple"
          onSelectionChanged={({ api }) => setSelectedRows(api.getSelectedRows())}
        />
      </div>
      <ConfirmationModal
        onConfirm={onConfirmRemoveAdmins}
        loading={saving}
        onClose={() => setModalOpened(null)}
        opened={modalOpened === 'Remove admin'}
        buttonColor="red"
        description={`Are you sure you want to remove ${
          selectedRows.length === 1
            ? `${selectedRows[0].userEmail} as a company admin`
            : `${selectedRows.length} users as company admins`
        }?`}
      />
    </>
  );
};

export const CompanyPropertiesPage = () => {
  const { user } = useUser();
  const { data: company, apiCall } = useWrappedGet<Company>(`admin/company/:companyId`, {
    lazy: true,
  });
  useEffect(() => {
    const companyId = user?.companyId;
    if (isNotNil(companyId)) {
      apiCall({ url: `admin/company/${companyId}` });
    }
  }, [apiCall, user?.companyId]);
  if (isNil(user) || isNil(company)) {
    return <Loader m="lg" />;
  }
  return <CompanyPropertiesPageImp user={user} company={company} />;
};
