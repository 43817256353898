import './grid-layout.css';

import { ReactNode } from 'react';

import { Box } from '@mantine/core';
import { WidthProvider, Responsive } from 'react-grid-layout';

import { buildDefaultLayoutSettings } from 'constants/globalConstants';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const StyledGridLayout = (
  props: ReturnType<typeof buildDefaultLayoutSettings> & {
    children: ReactNode;
  },
) => (
  <Box
    className="grid-layout-box"
    style={{
      gap: 0,
      position: 'relative',
      transition: 'height 200ms ease',
      flexGrow: 1,
      maxWidth: 1940,
    }}
  >
    <ResponsiveReactGridLayout compactType="horizontal" {...props} />
  </Box>
);

export default StyledGridLayout;
