import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Divider, Flex } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { strongValues } from 'helpers/strongEntries';
import useOnScreen, { TriggerOnVisible } from 'hooks/useOnScreen';

import { PartDecision, PartDecisionMakerModal } from '../PartDecisionMakerModal';
import { AddCatalogPartCard } from './AddCatalogPartCard';
import type { Part, PartId, PartListingProps } from './types';
import { useCatalogCategoryParts } from './useCatalogCategoryParts';

export type PartToAdd = {
  part: Part;
  quantity: number;
};

export type PartToAddWithDecisions = PartToAdd & {
  decisions?: PartDecision['decisions'];
};

export const PartCategoryPartAdder = ({
  overridePartName,
  goBack,
  onAddParts,
  disableAssemblies,
  exclude,
  requireDecisions = false,
  ...props
}: PartListingProps & {
  onAddParts: (items: PartToAddWithDecisions[]) => Promise<void>;
  exclude?: PartId[];
  disableAssemblies?: boolean;
  requireDecisions?: boolean;
}) => {
  const dividerRef = useRef<HTMLDivElement>(null);
  const partListRef = useRef<HTMLDivElement>(null);
  const onScreenRef = useRef<HTMLDivElement>(null);
  const partsOnScreen = useOnScreen(partListRef);
  const dividerMarkerOnScreen = useOnScreen(onScreenRef);
  const [multiplePartsMap, setMultiplePartsMap] = useState<Record<PartId, number>>({});
  const reset = useCallback(() => setMultiplePartsMap({}), []);
  const [partsWaitingToAdd, setPartsWaitingToAdd] = useState<PartToAdd[]>([]);
  const [partsNeedingDecision, setPartsNeedingDecision] = useState<PartToAdd[]>([]);
  const { parts, loadingParts, fetchMoreParts } = useCatalogCategoryParts({
    ...props,
    overridePartName,
    reset,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (overridePartName && !loadingParts) {
      partListRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loadingParts, overridePartName]);

  const updateMultiPartsMap = (partId: PartId, qty: number | undefined) => {
    setMultiplePartsMap((map) => ({
      ...map,
      [partId]: qty,
    }));
  };

  const onDecisionsMade = async (decisions: Record<PartId, PartDecision>, partsToAdd = partsWaitingToAdd) => {
    setSaving(true);
    await onAddParts(
      partsToAdd.map((p) => ({
        ...p,
        decisions: decisions[p.part.partId]?.decisions,
      })),
    ).finally(() => setSaving(false));
    setPartsNeedingDecision([]);
    setPartsWaitingToAdd([]);
    setMultiplePartsMap({});
    props.refresh?.();
  };

  const onAddCatalogItems = (partsToAdd: PartToAdd[]) => {
    const needsDecisions = partsToAdd.filter((p) =>
      p.part.attriubuteVariantData.some((v) => v.selectValues.length > 0),
    );
    if (requireDecisions && needsDecisions.length > 0) {
      setPartsNeedingDecision(needsDecisions);
      setPartsWaitingToAdd(partsToAdd);
    } else {
      onDecisionsMade({}, partsToAdd);
    }
  };

  const addSingleCatalogItem = (part: Part) => {
    const quantity = multiplePartsMap[part.partId];
    onAddCatalogItems([{ part, quantity }]);
  };

  const addAllCatalogItems = () => {
    const items = Object.entries(multiplePartsMap)
      .filter(([, qty]) => !!qty && qty > 0)
      .map(([partId, quantity]) => ({
        part: parts.find((p) => p.partId === partId),
        quantity,
      }))
      .filter((p): p is PartToAdd => isNotNil(p.part));
    onAddCatalogItems(items);
  };

  const lockDivider = !dividerMarkerOnScreen && partsOnScreen;

  return (
    <>
      <div ref={onScreenRef} style={{ marginBottom: 2, width: '100%' }} />
      <Flex
        ref={dividerRef}
        align="center"
        justify="space-between"
        gap="sm"
        bg="white"
        style={{
          width: onScreenRef.current?.clientWidth || '100%',
          position: lockDivider ? 'fixed' : undefined,
          zIndex: 1,
        }}
      >
        {isNotNil(overridePartName) && (
          <Button
            size="xs"
            compact
            onClick={goBack}
            variant="outline"
            leftIcon={<EvolveIcon size="xs" icon="ArrowLeft" color="inherit" />}
            color="gray.8"
          >
            Go back
          </Button>
        )}
        <Divider
          w="100%"
          c="dimmed"
          my="md"
          label={
            loadingParts
              ? 'Loading parts...'
              : `${parts?.length ?? 0} part${parts?.length === 1 ? '' : 's'} ${
                  isNil(overridePartName) ? 'in category' : 'from search'
                }`
          }
        />
        {isNil(overridePartName) && (
          <Button
            size="xs"
            mr="xs"
            compact
            loading={saving}
            disabled={!strongValues(multiplePartsMap).some((v) => !!v)}
            onClick={addAllCatalogItems}
            variant="outline"
            leftIcon={<EvolveIcon size="xs" icon="Add" color="inherit" />}
          >
            Add all
          </Button>
        )}
      </Flex>
      <Flex
        ref={partListRef}
        direction="column"
        gap="2rem"
        mt={lockDivider ? dividerRef.current?.clientHeight : undefined}
      >
        {parts?.map((p) => (
          <AddCatalogPartCard
            key={p.partId}
            part={p}
            multiplePartsMap={multiplePartsMap}
            updateMultiPartsMap={updateMultiPartsMap}
            addSingleCatalogItem={addSingleCatalogItem}
            saving={saving || partsNeedingDecision.length > 0}
            placeholder={disableAssemblies ? 'Unit Qty' : 'Qty'}
            disabled={(disableAssemblies && p.hasAssembly) || exclude?.includes(p.partId)}
          />
        ))}
        {parts?.length > 0 && <TriggerOnVisible onVisible={fetchMoreParts} loading={loadingParts} />}
      </Flex>
      <PartDecisionMakerModal
        parts={partsNeedingDecision}
        onSubmit={onDecisionsMade}
        onClose={() => {
          setPartsWaitingToAdd([]);
          setPartsNeedingDecision([]);
        }}
      />
    </>
  );
};
