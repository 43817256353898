import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

export type ChangePasswordBody = {
  currentPassword: string;
  newPassword: string;
};

export const useResetPassword = () => {
  const { apiCall: resetPassword, ...rest } = useWrappedPost<unknown, ChangePasswordBody>(
    'admin/authentication/changePassword',
  );
  return {
    resetPassword,
    ...rest,
  };
};
