import type { ComponentProps, CSSProperties } from 'react';

import type { Calendar } from '@mantine/dates';

export const highlightDayStyles: CSSProperties = {
  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: 'var(--mantine-primary-color-5)',
};

export const calendarStyles: ComponentProps<typeof Calendar>['styles'] = {
  calendar: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  monthLevelGroup: {
    width: '100%',
    height: '100%',
  },
  yearLevelGroup: {
    width: '100%',
    height: '100%',
  },
  decadeLevelGroup: {
    width: '100%',
    height: '100%',
  },
  monthLevel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  yearLevel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  decadeLevel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  monthsList: {
    height: '100%',
  },
  yearsList: {
    height: '100%',
  },
  calendarHeader: {
    maxWidth: 'none',
    width: '100%',
  },
  calendarHeaderLevel: {
    fontSize: 16,
  },
  weekdaysRow: {
    width: '100%',
    display: 'flex',
  },
  weekday: {
    flex: 1,
  },
  month: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  monthTbody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  monthRow: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  monthsListRow: {
    display: 'flex',
    width: '100%',
    height: '25%',
  },
  yearsListRow: {
    display: 'flex',
    width: '100%',
    height: '25%',
  },
  monthCell: {
    flex: 1,
    verticalAlign: 'initial',
    overflow: 'hidden',
    display: 'flex',
  },
  monthsListCell: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  yearsListCell: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pickerControl: {
    fontSize: 16,
    fontWeight: 500,
    height: '100%',
    width: '100%',
  },
  day: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'unset',
    height: 'unset',
    overflow: 'hidden',
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,.2)',
    borderWidth: 1,
  },
};
