import { Flex } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';
import { format, isValid, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { sortByStatusKey, taskStatusTypeNames } from 'constants/badgeMappingStatus';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { customSetFilterParams, filterStatusConverter } from 'helpers/ag-grid/convertAgGridRequest';
import { isNotNil } from 'helpers/isNotNil';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { WorkCellTaskLink } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/columnDefs';
import { getDaysRemainingWorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/common';
import type {
  TaskStatusType,
  WorkCell,
  WorkCellTask,
} from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { TaskPlayPauseIcon } from './TaskPlayPauseIcon';

export const getWorkCellsColDef = (
  taskStatusTypes: TaskStatusType[],
  workCells: Pick<WorkCell, 'workCellId' | 'workCellName'>[],
  showOpen: boolean,
): ColDef<WorkCellTask>[] => [
  {
    ...lockedColDef(),
    cellRenderer: 'agGroupCellRenderer',
    width: 48,
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    cellRenderer: CellRenderer<WorkCellTask>(({ value, data }) => (
      <EvolveLink to={`/shop/work-orders/${data.workOrderId}`} from="Work Cells">
        <Flex align="center" gap="xs">
          <EvolveIcon icon="WorkOrder" />
          {value}
        </Flex>
      </EvolveLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequestName',
    headerName: 'Work Order Name',
    minWidth: 220,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
    },
  },
  {
    field: 'workRequestItemName',
    headerName: 'Item Name',
    flex: 1,
    minWidth: 220,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
    },
  },
  {
    field: 'taskName',
    flex: 1,
    minWidth: 220,
    cellRenderer: CellRenderer<WorkCellTask>(({ value, data }) => (
      <WorkCellTaskLink task={data} from="Work Cells">
        <Flex align="center" gap="xs">
          <EvolveIcon icon="WorkCellTask" />
          {value}
        </Flex>
      </WorkCellTaskLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workCellId',
    headerName: 'Work Cell',
    valueGetter: ({ data }) => data?.workCellName,
    filter: 'agSetColumnFilter',
    filterParams: customSetFilterParams(workCells, 'workCellId', 'workCellName'),
  },
  {
    field: 'taskStatusTypeId',
    headerName: 'Status',
    minWidth: 130,
    width: 120,
    cellRenderer: CellRenderer<WorkCellTask>(({ data }) => (
      <Flex gap={6} align="center">
        <CellStatusBadge status={data.taskStatusTypeName} daysRemaining={getDaysRemainingWorkCellTask(data)} />
        <TaskPlayPauseIcon taskStatusTypeName={data.taskStatusTypeName} />
      </Flex>
    )),
    sortable: false,
    filter: showOpen ? 'customValueSetFilter' : false,
    filterParams: filterStatusConverter(
      taskStatusTypes.sort(sortByStatusKey(taskStatusTypeNames, 'taskStatusTypeName')),
      'taskStatusTypeId',
      'taskStatusTypeName',
    ),
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    width: 150,
    valueFormatter: (p) => {
      const date = parseISO(`${p.value}Z`);
      return isValid(date) ? format(date, 'MM/dd/yyyy') : '';
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    headerName: 'Days Remaining',
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data) ? getDaysRemainingWorkCellTask(data) : ''),
    cellStyle: ({ value }) => ({
      ...(value < 0 ? { color: 'red' } : {}),
    }),
    sortable: false,
  },
];
