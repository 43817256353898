import { useEffect, useRef, useState } from 'react';

import { TextInput } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { baseColumnDef, baseGridDef } from 'helpers/ag-grid/baseColumnDef';
import { isNil } from 'helpers/isNotNil';
import { Project, useProjects } from 'hooks/projectsAndFacilities/useProjects';

import { CreateProjectModal } from './CreateProjectModal';
import { EditProjectLink } from './EditProjectLink';
import { ModifyProjectMenu } from './ModifyProjectMenu';

const colDef: ColDef<Project>[] = [
  {
    headerName: 'Id',
    field: 'projectIdentifier',
  },
  {
    field: 'projectName',
    valueGetter: ({ data }) => data?.projectName,
    flex: 1,
    minWidth: 150,
    cellRenderer: CellRenderer<Project, Project['projectName']>(({ data }) => (
      <EditProjectLink project={data} showSelected />
    )),
  },
  {
    headerName: 'Type',
    flex: 1,
    minWidth: 150,
    field: 'projectType.projectTypeName',
  },
  {
    width: 64,
    cellRenderer: CellRenderer<Project>(({ data }) => <ModifyProjectMenu project={data} />),
    sortable: false,
    lockPosition: 'right',
    resizable: false,
    suppressColumnsToolPanel: true,
  },
];

export const ProjectsPage = () => {
  const { user } = useUser();
  const gridRef = useRef<AgGridReact<Project>>(null);
  const { loading, projects, refetch } = useProjects();
  const [quickFilterText, setQuickFilterText] = useState('');

  // TODO: Remove once "update project" page is using new useProjects hook
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (loading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [loading]);

  if (isNil(user)) {
    return null;
  }

  return (
    <>
      <BasePageHeader
        title="Projects"
        topRightComponent={
          <>
            <TextInput
              placeholder="Search..."
              icon={<EvolveIcon icon="Search" color="inherit" />}
              value={quickFilterText}
              onChange={(e) => setQuickFilterText(e.target.value)}
            />
            <CreateProjectModal companyId={user?.companyId} />
          </>
        }
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          {...baseGridDef}
          defaultColDef={{
            ...baseColumnDef,
            valueFormatter: ({ value }) => value ?? '--',
          }}
          // TODO: uncomment on AG Grid 32
          // loading={loading}
          columnDefs={colDef}
          rowModelType="clientSide"
          rowData={projects}
          quickFilterText={quickFilterText}
        />
      </div>
    </>
  );
};
