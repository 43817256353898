import React, { useState, useMemo, ReactNode, useCallback, useEffect } from 'react';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { getLocalStorage } from 'hooks/useLocalStorage';
import { User } from 'types/types-api';

type UpdateUserType = {
  user: User;
  getPresignedURL: string;
};

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  setUpdateUserListener: (params: UpdateUserType) => void;
  updateUserListener: UpdateUserType | null;
  refreshUser: () => Promise<void>;
};

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

const { EVOLVE_TOKEN_USERNAME } = LOCAL_STORAGE_CONSTANTS;

type Props = {
  children: ReactNode;
};

export const UserProvider = ({ children }: Props) => {
  const { apiCall: getUserByName, loading: apiLoading } = useWrappedGet<User>('admin/user/username/', { lazy: true });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [updateUserListener, setUpdateUserListener] = useState<UserContextType['updateUserListener']>(null);

  const pullUser = useCallback(async () => {
    try {
      setLoading(true);
      const username = getLocalStorage(EVOLVE_TOKEN_USERNAME);
      if (username) {
        const res = await getUserByName({
          url: `admin/user/username/${username}`,
        });
        setUser(res);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [getUserByName]);

  useEffect(() => {
    pullUser();
  }, [pullUser]);

  const userObj = useMemo(
    () => ({
      user,
      setUser,
      loading: loading || apiLoading,
      refreshUser: pullUser,
      // These seem to only be used for user profile pics,
      // and should be revisited/rewritten
      setUpdateUserListener,
      updateUserListener,
    }),
    [user, loading, apiLoading, pullUser, updateUserListener],
  );

  return <UserContext.Provider value={userObj}>{children}</UserContext.Provider>;
};

export const useUser = () => useGeneralContext(UserContext, 'User');
