import { Box } from '@mantine/core';
import parse from 'html-react-parser';

export const HTMLPreview = ({ html, preview }: { html: string; preview?: boolean }) => (
  <Box
    style={{
      flex: 1,
      overflow: 'scroll',
    }}
    mah={preview ? 300 : undefined}
    miw={500}
    p={0}
    bg="white"
  >
    {parse(html)}
  </Box>
);
