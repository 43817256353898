import { useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetLinkFromQrId } from 'hooks-api/useGetLinkFromQRId';

const QRRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState<string | undefined>(undefined);

  const { getLinkFromQrCode } = useGetLinkFromQrId();

  useEffect(() => {
    if (!params?.id) return;
    getLinkFromQrCode(params?.id).then((response) => {
      if (!response || response.status !== 200 || !response.url) {
        navigate('/auth');
        return;
      }

      setUrl(response?.url);
    });
  }, [navigate, params?.id, getLinkFromQrCode]);

  if (url) {
    window.location.href = url;
    return <></>;
  }

  return <Loader />;
};

export default QRRedirect;
