import React, { Children, ReactNode, useMemo } from 'react';

import GridLayout from 'app/Layout/GridLayout';

import ComponentPane from './ComponentPane';

type Props = {
  children: ReactNode;
  sizes?: number[];
  /** TODO: Remove after full conversion */
  flyoutMenu?: ReactNode;
  componentPaneProps?: React.ComponentProps<typeof ComponentPane>;
};

const ComponentPaneLayout = ({ sizes, flyoutMenu, children, componentPaneProps }: Props) => {
  const childrenPanes = useMemo(
    () =>
      Children.map(children, (child) => <ComponentPane {...componentPaneProps}>{child}</ComponentPane>)?.filter(
        (child) => !!child.props.children,
      ) ?? [],
    [children, componentPaneProps],
  );

  return (
    <>
      <GridLayout sizes={sizes} panes={childrenPanes} />
      {flyoutMenu}
    </>
  );
};

export default ComponentPaneLayout;
