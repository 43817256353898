import { useState } from 'react';

import { Button, Flex, Modal, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { isNil, isNotNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';
import type {
  Part,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import { AddEditItemDetails } from './AddEditItemDetails';
import type { ConvertToAssemblyAdditionalDetails } from './AddEditItemForm';
import { PartEditorProvider } from './usePartEditor';

type Props = {
  part?: Part;
  refresh?: () => void;
  partCategoryId: PartCategoryId | null;
  disabled?: boolean;
};

export const AddEditItemModal = ({ part, partCategoryId, refresh, disabled }: Props) => {
  const { currentModule } = useSetupModule();
  const navigate = useEvolveNavigate('Catalog Setup');
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Button
        disabled={isNil(partCategoryId) || disabled}
        variant={isNotNil(part) ? 'outline' : undefined}
        leftIcon={<EvolveIcon icon={isNotNil(part) ? 'WriteInItem' : 'Add'} size="sm" color="inherit" />}
        onClick={() => {
          if (!part?.hasAssembly) {
            setOpened(true);
          } else {
            navigate(`/${currentModule()?.toLocaleLowerCase()}/edit-catalog-item/${part.partId}`, {
              additionalData: {
                part,
              } as ConvertToAssemblyAdditionalDetails,
            });
          }
        }}
      >
        {isNotNil(part) ? 'Edit' : 'Add item'}
      </Button>
      <Modal
        title={
          <Flex justify="space-between" align="center" pr="xl">
            {isNotNil(part) ? <Text>Edit {part.partName}</Text> : <Text>Add Item</Text>}
          </Flex>
        }
        styles={{
          title: { width: '100%' },
        }}
        opened={opened}
        onClose={() => setOpened(false)}
        centered
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        {isNotNil(partCategoryId) && (
          <PartEditorProvider part={part} partCategoryId={partCategoryId}>
            {() => (
              <AddEditItemDetails
                refresh={() => {
                  setOpened(false);
                  refresh?.();
                }}
                onClose={() => setOpened(false)}
              />
            )}
          </PartEditorProvider>
        )}
      </Modal>
    </>
  );
};
