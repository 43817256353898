import { useEffect, useMemo, useState } from 'react';

import { Button, Card, Divider, Flex, Modal, Text } from '@mantine/core';

import { useUser } from 'app/UserContext';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { isNil } from 'helpers/isNotNil';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { User } from 'types/types-api';

type Props = {
  workRequest: WorkRequest;
  disabled?: boolean;
};

const verbiageMap: Partial<
  Record<
    WorkRequestStatusName,
    {
      progressive: string;
      past: string;
      action: string;
    }
  >
> = {
  Rejected: {
    progressive: 'rejection',
    past: 'rejected',
    action: 'Accept',
  },
  Canceled: {
    progressive: 'cancelation',
    past: 'cancelled',
    action: 'Close',
  },
} as const;

export const getWorkRequestRejectedStorageKey = (user: User | null, workRequest: WorkRequest) =>
  `${user?.userId}-${workRequest.workRequestIdentifier}-${workRequest.workRequestStatusName}-accepted`;

export const WorkRequestRejectedModal = ({ workRequest, disabled }: Props) => {
  const { user } = useUser();
  const [opened, setOpened] = useState(false);
  const [canAccept, setCanAccept] = useState(true);

  const storageKey = useMemo(() => getWorkRequestRejectedStorageKey(user, workRequest), [user, workRequest]);

  useEffect(() => {
    const lastStatusUpdateAccepted = getLocalStorage<string | boolean>(storageKey);
    if (workRequest.workRequestStatusName === 'Rejected') {
      if (lastStatusUpdateAccepted === true) return;
      if (!lastStatusUpdateAccepted || lastStatusUpdateAccepted !== workRequest.lastStatusUpdate) {
        setTimeout(() => {
          setCanAccept(false);
          setOpened(true);
          setTimeout(() => setCanAccept(true), 1500);
        }, 250);
      }
    } else {
      removeLocalStorage(storageKey);
    }
  }, [storageKey, workRequest.lastStatusUpdate, workRequest.workRequestStatusName]);

  const onAccept = () => {
    setLocalStorage(storageKey, workRequest.lastStatusUpdate ?? true);
    setOpened(false);
  };

  const verbiage = verbiageMap[workRequest.workRequestStatusName];

  if (isNil(verbiage)) return null;

  return (
    <>
      <Button variant="subtle" onClick={() => setOpened(true)} disabled={disabled || opened}>
        View {verbiage.progressive}
      </Button>
      <Modal
        title={`Work request ${verbiage.progressive}`}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        centered
      >
        <Flex direction="column" gap="sm">
          <Text fz="sm">
            {workRequest.workRequestIdentifier} was {verbiage.past} for the following reason:
          </Text>
          <Card fz="sm" bg="gray.1" p="xs">
            <Text>{workRequest.workRequestStatusDescription}</Text>
          </Card>
          <Divider />
          <Flex justify="flex-end">
            <Button onClick={onAccept} disabled={!canAccept}>
              {verbiage.action}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
