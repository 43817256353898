import { useMemo, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { EVOLVE_COMPANY_ID } from 'constants/globalConstants';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import type { User, UserId } from 'types/types-api';

import { getEvolveMembersColDef } from './columnDefs';

export const EvolveMembersPage = () => {
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<User[]>([]);

  const { fetchPage, searchHandler } = useWrappedPaginatedGet<User>('admin/user', {
    lazy: true,
    defaultConfig: {
      params: {
        companyId: EVOLVE_COMPANY_ID,
      },
    },
  });
  const { apiCall: deleteEvolveMembers, loading: deleting } = useWrappedPatch<unknown, { userIds: UserId[] }>(
    'admin/user/multidelete',
  );

  const colDef = useMemo(() => getEvolveMembersColDef({ deleteUser: setUsersToDelete }), []);

  const { agGridProps, refreshGrid } = useServerSideGrid({
    tableName: 'evolve-members',
    fetchPage,
    colDef,
    rowId: 'userId',
  });

  const onConfirmDeleteEvolveUsers = () => {
    deleteEvolveMembers({
      userIds: usersToDelete.map((u) => u.userId),
    }).then(() => {
      setUsersToDelete([]);
      setSelectedRows([]);
      agGridProps.ref.current?.api.deselectAll();
      refreshGrid();
    });
  };

  return (
    <>
      <BasePageHeader
        title="Evolve Members"
        bottomLeftComponent={
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value=""
            placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
            data={['Delete']}
            onChange={(selected) => {
              if (selected === 'Delete') {
                setUsersToDelete(selectedRows);
              }
            }}
          />
        }
        topRightComponent={<InviteUsersModal pageType="company" refresh={refreshGrid} companyId={EVOLVE_COMPANY_ID} />}
        bottomRightComponent={
          <TextInputDebounced
            icon={<EvolveIcon icon="Search" color="inherit" />}
            onChange={searchHandler}
            placeholder="Search..."
          />
        }
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<User>
          {...agGridProps}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
        />
      </div>
      <ConfirmationModal
        onConfirm={onConfirmDeleteEvolveUsers}
        loading={deleting}
        onClose={() => {
          setUsersToDelete([]);
        }}
        opened={usersToDelete.length > 0}
        buttonColor="red"
        description={`Are you sure you want to delete ${usersToDelete.length === 1 ? 'this user' : 'these users'}?`}
      />
    </>
  );
};
