import { useReactFlow } from '@xyflow/react';

import { isNotNil } from 'helpers/isNotNil';
import { Catalog } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/Catalog';
import {
  PartCategoryPartAdder,
  PartToAdd,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/PartCategoryPartAdder';

import { useAssemblyEditor } from './useAssemblyEditor';
import { usePartEditor } from './usePartEditor';
import { useCreateNodesFromParts } from './utils';

export const AddPartToAssembly = () => {
  const { createNodesFromParts } = useCreateNodesFromParts();
  const { part, companyId, publishing } = usePartEditor();
  const { addNode } = useAssemblyEditor();
  const { fitView, getViewport } = useReactFlow();

  const addSelectedParts = async (parts: PartToAdd[]) =>
    new Promise<void>((resolve) => {
      createNodesFromParts(parts).map(addNode);
      setTimeout(() => {
        resolve();
        fitView({
          duration: 300,
          maxZoom: getViewport().zoom,
          padding: 0,
        });
      }, 250);
    });

  return (
    <Catalog companyId={companyId} disabled={publishing} useLocalStorageCatalog={false}>
      {(listingProps) => (
        <PartCategoryPartAdder
          {...listingProps}
          disableAssemblies
          exclude={isNotNil(part) ? [part.partId] : undefined}
          onAddParts={addSelectedParts}
        />
      )}
    </Catalog>
  );
};
