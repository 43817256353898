import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { useUser } from 'app/UserContext';
import { MESSAGE_PRIORITIES } from 'constants/globalConstants';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedDelete, useWrappedGet, useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';

import { Message, MessageDetails, MessageId, MessagesReq, UpdateMessageBody } from './types';

type MessagesContextType = {
  deleteMessage: (messageId: MessageId) => Promise<void>;
  updateMessage: (messageId: MessageId, status: UpdateMessageBody['status']) => Promise<void>;
  updating: boolean;
  markAllRead: () => Promise<void>;
  markingRead: boolean;
  getMessageDetails: (messageId: MessageId) => Promise<MessageDetails>;
  gettingMessageDetails: boolean;
  undismissedHighPriorityMessages: Message[];
  setUndismissedHighPriorityMessages: (value: Message[]) => void;
  dismissAllHighPriority: () => Promise<void>;
  dismissingAll: boolean;
  totalUnread: number;
  refreshUnreadCount: () => Promise<void>;
};

export const MessagesContext = createContext<MessagesContextType | undefined>(undefined);

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const [undismissedHighPriorityMessages, setUndismissedHighPriorityMessages] = useState<Message[]>([]);
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const { user } = useUser();

  const { entireCount: unreadFromNetwork, refetch: refreshUnreadCount } = useWrappedPaginatedGet<Message>(
    'message/message',
    {
      lazy: false,
      perPage: 1,
      defaultConfig: {
        params: {
          read: false,
          status: 'Active Dismissed',
        },
      },
    },
  );
  useEffect(() => {
    if (isNotNil(unreadFromNetwork)) {
      setTotalUnread(unreadFromNetwork);
    }
  }, [unreadFromNetwork]);

  const { fetchPage: getHighPriorityMessages } = useWrappedPaginatedGet<Message, MessagesReq>('message/message', {
    lazy: true,
    defaultConfig: {
      params: {
        messagePriorityId: MESSAGE_PRIORITIES.HIGH,
        status: 'Active',
      },
    },
  });
  const { apiCall: updateMessageCall, loading: updating } = useWrappedPatch<void, UpdateMessageBody>('message/message');
  const { apiCall: deleteMessageCall, loading: deleting } = useWrappedDelete<void>('message/message');
  const { apiCall: getMessageDetailsCall, loading: gettingMessageDetails } = useWrappedGet<MessageDetails>(
    'message/message/:id',
    { lazy: true },
  );
  const { apiCall: markAllReadCall, loading: markingRead } = useWrappedPatch<void, unknown>('message/message/readAll');
  const { apiCall: dismissAllCall, loading: dismissingAll } = useWrappedPatch<void, unknown>(
    'message/message/dismissAll',
  );

  const deleteMessage = useCallback(
    (messageId: MessageId) =>
      deleteMessageCall({ url: `message/message/${messageId}` }).then(() => {
        refreshUnreadCount();
      }),
    [deleteMessageCall, refreshUnreadCount],
  );

  const updateMessage = useCallback(
    (messageId: MessageId, status: UpdateMessageBody['status']) =>
      updateMessageCall({ status }, { url: `message/message/${messageId}` }).then(() => {
        refreshUnreadCount();
      }),
    [updateMessageCall, refreshUnreadCount],
  );

  const dismissAllHighPriority = useCallback(
    () =>
      dismissAllCall({}).then(() => {
        setUndismissedHighPriorityMessages([]);
        refreshUnreadCount();
      }),
    [dismissAllCall, refreshUnreadCount],
  );

  const getMessageDetails = useCallback(
    (messageId: MessageId) => getMessageDetailsCall({ url: `message/message/${messageId}` }),
    [getMessageDetailsCall],
  );

  const markAllRead = useCallback(
    () =>
      markAllReadCall({}).then(() => {
        refreshUnreadCount();
      }),
    [markAllReadCall, refreshUnreadCount],
  );

  useEffect(() => {
    if (isNotNil(user)) {
      getAllDataFromFetcher(getHighPriorityMessages).then(setUndismissedHighPriorityMessages);
    }
  }, [getHighPriorityMessages, user]);

  const messagesObj = useMemo<MessagesContextType>(
    () => ({
      deleteMessage,
      deleting,
      updateMessage,
      updating,
      dismissAllHighPriority,
      dismissingAll,
      markAllRead,
      markingRead,
      getMessageDetails,
      gettingMessageDetails,
      undismissedHighPriorityMessages,
      setUndismissedHighPriorityMessages,
      totalUnread,
      refreshUnreadCount,
    }),
    [
      deleteMessage,
      deleting,
      updateMessage,
      updating,
      dismissAllHighPriority,
      dismissingAll,
      markAllRead,
      markingRead,
      getMessageDetails,
      gettingMessageDetails,
      undismissedHighPriorityMessages,
      totalUnread,
      refreshUnreadCount,
    ],
  );
  return <MessagesContext.Provider value={messagesObj}>{children}</MessagesContext.Provider>;
};

export const useMessages = () => useGeneralContext(MessagesContext, 'Messages');
