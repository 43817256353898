import { useMemo, useState } from 'react';

import { Flex, Modal, TextInput, Loader, NumberInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useUser } from 'app/UserContext';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { strongFromEntries } from 'helpers/strongEntries';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { DepartmentType } from 'hooks/projectsAndFacilities/types';
import { FacilityId, useFacilities, type FacilityUpdate } from 'hooks/projectsAndFacilities/useFacilities';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { User } from 'types/types-api';

type Props = {
  facilityId?: FacilityId | null;
  opened: boolean;
  onClose: () => void;
};

const CreateEditFacilityForm = ({
  user,
  facilityId,
  onClose,
  departmentTypes,
}: Props & {
  departmentTypes: DepartmentType[];
  user: User;
}) => {
  const { selectFacility } = useSelectedProjectFacility();
  const [saving, setSaving] = useState(false);
  const { facilities, createFacility, updateFacility } = useFacilities();
  const facility = facilities.find((f) => f.facilityId === facilityId);

  const form = useForm<FacilityUpdate>({
    initialValues: {
      facilityName: facility?.facilityName ?? '',
      companyId: facility?.companyId ?? user.companyId,
      address: {
        addressLine1: facility?.address.addressLine1 ?? '',
        addressLine2: facility?.address.addressLine2 ?? '',
        city: facility?.address.city ?? '',
        state: facility?.address.state ?? '',
        zip: facility?.address.zip ?? '',
        country: facility?.address.country ?? 'United States',
      },
      deliveryLeadTime: facility?.deliveryLeadTime ?? 0,
    },
    validate: {
      facilityName: (n) => !n,
      address: {
        addressLine1: (v) => !v,
        city: (v) => !v,
        state: (v) => !v,
        zip: (v) => !v,
        country: (v) => !v,
      },
      deliveryLeadTime: (v) => !Number.isInteger(v),
    },
  });
  const depTypeMap = useMemo(
    () => strongFromEntries(departmentTypes.map((dt) => [dt.departmentTypeName, dt.departmentTypeId]) ?? []),
    [departmentTypes],
  );
  const onSubmit = (values: typeof form.values) => {
    setSaving(true);
    (isNotNil(facility)
      ? updateFacility(facility.facilityId, values)
      : createFacility({
          ...values,
          departments: [
            {
              departmentName: values.facilityName,
              departmentTypeId: depTypeMap.Shop,
              isAdmin: true,
              userId: user.userId,
            },
          ],
        }).then(selectFacility)
    )
      .then(onClose)
      .finally(() => setSaving(false));
  };
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap="xs">
        <TextInput
          label="Facility Name"
          autoFocus
          maxLength={64}
          withAsterisk
          disabled={saving}
          {...form.getInputProps('facilityName')}
        />
        <TextInput
          label="Address Line 1"
          name="Address Line 1"
          required
          disabled={saving}
          {...form.getInputProps('address.addressLine1')}
        />
        <TextInput
          label="Address Line 2"
          name="Address Line 2"
          disabled={saving}
          {...form.getInputProps('address.addressLine2')}
        />
        <Flex gap="md">
          <TextInput
            style={{ width: '100%' }}
            label="State"
            name="State"
            required
            disabled={saving}
            {...form.getInputProps('address.state')}
          />
          <TextInput
            label="Zip/Postal Code"
            name="Zip/Postal Code"
            required
            disabled={saving}
            {...form.getInputProps('address.zip')}
          />
        </Flex>
        <TextInput label="City" name="City" required disabled={saving} {...form.getInputProps('address.city')} />
        <TextInput label="Country" name="Country" required disabled {...form.getInputProps('address.country')} />
        <Flex>
          <NumberInput
            label="Delivery Lead Time"
            required
            rightSection={
              <Text c="dimmed" fz="sm">
                days
              </Text>
            }
            type="number"
            // Mantine v7 only
            // allowDecimal={false}
            rightSectionProps={{ style: { marginRight: '10px' } }}
            min={0}
            {...form.getInputProps('deliveryLeadTime')}
          />
        </Flex>
      </Flex>
      <ModalButtons
        loading={saving}
        disabled={!form.isValid()}
        confirmationText={isNil(facility) ? 'Create' : 'Save changes'}
        onClose={onClose}
        type="submit"
        dividerMargin="xs"
      />
    </form>
  );
};

export const CreateEditFacilityModal = (props: Props) => {
  const { user } = useUser();
  const { opened, onClose, facilityId } = props;
  const { facilities } = useFacilities();
  const facility = facilities.find((f) => f.facilityId === facilityId);

  const { data: departmentTypes } = useWrappedGet<DepartmentType[]>('admin/departmenttype');

  return (
    <Modal
      yOffset={100}
      withinPortal
      size="lg"
      title={isNil(facility) ? 'Create new facility' : 'Edit facility'}
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      centered
    >
      {isNil(departmentTypes) || isNil(user) ? (
        <Loader />
      ) : (
        <CreateEditFacilityForm {...props} user={user} departmentTypes={departmentTypes} />
      )}
    </Modal>
  );
};
