import 'react-grid-layout/css/styles.css';
import React, { ReactElement, useMemo } from 'react';

import StyledGridLayout from 'app/Layout/ReactGridLayout/StyledGridLayout';
import { buildDefaultLayoutSettings } from 'constants/globalConstants';
import useBreakpoint from 'hooks/useBreakpoint';
import useWindowSize from 'hooks/useWindowSize';

import MobileRestricted from './MobileRestricted';

type Props = {
  sizes?: number[];
  panes: ReactElement[];
};

const MyGridLayout = ({ sizes, panes }: Props) => {
  const { isMobile } = useBreakpoint();
  const { height } = useWindowSize();

  const layoutSettings = useMemo(
    () => buildDefaultLayoutSettings(panes.length, height ?? 0, sizes),
    [height, panes.length, sizes],
  );

  if (isMobile) return <MobileRestricted />;
  return (
    <StyledGridLayout {...layoutSettings}>
      {panes.map((child, index) =>
        React.cloneElement(child, {
          key:
            // eslint-disable-next-line react/no-array-index-key
            index,
        }),
      )}
    </StyledGridLayout>
  );
};

export default MyGridLayout;
