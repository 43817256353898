import { useEffect, useRef, useState } from 'react';

import { TextInput, TextInputProps } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

type Props = Omit<TextInputProps, 'value' | 'onChange' | 'defaultValue'> & {
  delay?: number;
  defaultValue?: string;
  /** Debounced */
  onChange: (debouncedValue: string) => void;
  value?: string;
  /** Used for the current (not-debounced) value */
  onImmediateChange?: (value: string) => void;
};

export const TextInputDebounced = ({
  delay = 500,
  onChange,
  onImmediateChange,
  value: externalValue,
  ...props
}: Props) => {
  const valueHasEverChangedRef = useRef(false);
  const [value, setValue] = useState(props.defaultValue ?? '');
  useEffect(() => {
    if (isNotNil(externalValue)) {
      setValue(externalValue);
    }
  }, [externalValue]);

  useEffect(() => {
    if (valueHasEverChangedRef.current === false) return () => {};
    const delayInputTimeoutId = setTimeout(() => {
      onChange(value);
    }, delay);
    return () => clearTimeout(delayInputTimeoutId);
  }, [value, delay, onChange]);

  return (
    <TextInput
      {...props}
      value={value}
      onChange={(e) => {
        valueHasEverChangedRef.current = true;
        setValue(e.currentTarget.value);
        onImmediateChange?.(e.currentTarget.value);
      }}
    />
  );
};
