import { useCallback } from 'react';

import { useReactFlow, type Edge, type Node } from '@xyflow/react';
import dagre from 'dagre';

import { isNil } from 'helpers/isNotNil';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { ASSEMBLY_NODE_SHOP_TASK_TYPE_ID } from 'modules/Materials/AssemblyEditor/Utils/constants';
import type { PartToAdd } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/PartCategoryPartAdder';

import type { NodeType } from './nodes/AssemblyNode';
import { maxPartNodeWidth, maxTaskNodeWidth, partNodeHeight, taskNodeHeight } from './nodes/constants';
import type { Assembly } from './types';

export const getNodeInfoFromAssemblyNode = ({
  assemblyNodeType: { assemblyNodeTypeId },
  ...assemblyNode
}: Assembly['assemblyNodes'][number]): Omit<NodeType, 'id' | 'position'> | null => {
  if (assemblyNodeTypeId === ASSEMBLY_NODE_SHOP_TASK_TYPE_ID) {
    if (isNil(assemblyNode.shopTaskId)) return null;
    return {
      type: 'task',
      data: {
        taskType: {
          taskTypeId: assemblyNode.shopTaskId,
          taskTypeName: assemblyNode.assemblyNodeName,
          taskTypeDescription: assemblyNode.assemblyNodeDescription,
        },
      },
    };
  }
  const { part, quantity } = assemblyNode;
  if (isNil(part)) return null;
  return {
    type: part.hasAssembly ? 'assembly' : 'part',
    deletable: !part.hasAssembly,
    data: {
      part: {
        ...part,
        // the `part` returned from the Assembly API is missing the `description` field
        description: part.description ?? assemblyNode.assemblyNodeDescription,
      },
      quantity,
    },
  };
};

const bottomLeftMostNode = (nodes: Node[]) =>
  nodes.reduce((out: Node | null, curr) => {
    if (isNil(out)) return curr;
    if (curr.position.x < out.position.x) {
      return curr;
    }
    if (curr.position.x === out.position.x && curr.position.y > out.position.y) {
      return curr;
    }
    return out;
  }, null);

const bottomRightMostNode = (nodes: Node[]) =>
  nodes.reduce((out: Node | null, curr) => {
    if (isNil(out)) return curr;
    const currRightX = curr.position.x + (curr.measured?.width ?? 0);
    const outRightX = out.position.x + (out.measured?.width ?? 0);
    if (currRightX > outRightX) {
      return curr;
    }
    if (currRightX === outRightX && curr.position.y > out.position.y) {
      return curr;
    }
    return out;
  }, null);

export const useCalculatePartNodeStartingPoint = () => {
  const { getNodes, getNodesBounds } = useReactFlow();
  const calculatePartNodeStartingPoint = useCallback(() => {
    const existingNodes = getNodes();
    const leftMostPart = bottomLeftMostNode(existingNodes.filter((n) => n.type === 'part'));
    const leftMostTask = bottomLeftMostNode(existingNodes.filter((n) => n.type === 'task'));
    const bounds = getNodesBounds(existingNodes);
    let { x, y } =
      isNil(leftMostPart) || (leftMostTask?.position.x ?? bounds.x) < leftMostPart.position.x
        ? bounds
        : leftMostPart.position;

    if (existingNodes.length === 1) {
      x -= maxPartNodeWidth * 0.5;
      y -= partNodeHeight;
    }

    if (x !== leftMostPart?.position.x) {
      x -= maxPartNodeWidth - 12;
      y -= partNodeHeight;
    } else {
      y += partNodeHeight + 12;
    }
    return { x, y };
  }, [getNodesBounds, getNodes]);
  return { calculatePartNodeStartingPoint };
};

export const useCreateNodesFromParts = () => {
  const { calculatePartNodeStartingPoint } = useCalculatePartNodeStartingPoint();
  const createNodesFromParts = useCallback(
    (parts: PartToAdd[]): Omit<NodeType, 'id'>[] => {
      // eslint-disable-next-line prefer-const
      let { x, y } = calculatePartNodeStartingPoint();
      return parts.map(({ part, quantity }) => {
        const node = {
          type: 'part',
          data: {
            part: {
              documentIds: part.partImages?.flatMap((i) => i.document.documentId),
              ...part,
              unitOfMeasureCode: part.unitOfMeasure.unitOfMeasureCode,
            },
            quantity,
          },
          position: { x, y },
        };
        y += partNodeHeight + 12;
        return node;
      });
    },
    [calculatePartNodeStartingPoint],
  );
  return { createNodesFromParts };
};

export const useCalculateTaskNodeStartingPoint = () => {
  const { getNodes, getNodesBounds } = useReactFlow();
  const calculateTaskNodeStartingPoint = useCallback(() => {
    const existingNodes = getNodes();
    const rightMostPart = bottomRightMostNode(existingNodes.filter((n) => n.type !== 'task'));
    const rightMostTask = bottomRightMostNode(existingNodes.filter((n) => n.type === 'task'));

    const bounds = getNodesBounds(existingNodes);
    let { x, y } =
      isNil(rightMostTask) ||
      (rightMostPart?.position.x ?? bounds.x) > rightMostTask.position.x + (rightMostTask.measured?.width ?? 0)
        ? {
            ...bounds,
            x: bounds.x + bounds.width,
          }
        : rightMostTask.position;

    if (existingNodes.length === 1) {
      y -= taskNodeHeight * 3;
    }
    if (x !== rightMostTask?.position.x) {
      x += 30;
    } else {
      y += taskNodeHeight + 12;
    }
    return { x, y };
  }, [getNodes, getNodesBounds]);
  return { calculateTaskNodeStartingPoint };
};

export const useCreateNodesFromTasks = () => {
  const { calculateTaskNodeStartingPoint } = useCalculateTaskNodeStartingPoint();
  const createNodesFromTasks = useCallback(
    (tasks: TaskType[]): Omit<NodeType, 'id'>[] => {
      // eslint-disable-next-line prefer-const
      let { x, y } = calculateTaskNodeStartingPoint();
      return tasks.map((taskType) => {
        const node = {
          type: 'task',
          data: { taskType },
          position: { x, y },
        };
        y += taskNodeHeight + 12;
        return node;
      });
    },
    [calculateTaskNodeStartingPoint],
  );
  return { createNodesFromTasks };
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
export const autoLayoutedNodes = (nodes: Node[], edges: Edge[]) => {
  dagreGraph.setGraph({ rankdir: 'LR' });
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: maxTaskNodeWidth * (3 / 4),
      height: taskNodeHeight,
    });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  return nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - maxTaskNodeWidth / 2,
        y: nodeWithPosition.y - (node.type === 'task' ? taskNodeHeight : partNodeHeight) / 2,
      },
    };
  });
};
