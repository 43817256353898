import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import type { Project } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';

type Props = {
  project: Project;
  showSelected?: boolean;
};

export const EditProjectLink = ({ project, showSelected = false }: Props) => {
  const { currentModule } = useSetupModule();
  const { selectedItem, selectProject } = useSelectedProjectFacility();
  const module = currentModule() ?? 'admin';
  const onClick = () => {
    selectProject(project);
  };
  return (
    <>
      <EvolveLink to={`/${module.toLocaleLowerCase()}/properties/project-properties`} onClick={onClick} from="Projects">
        {project.projectName}
      </EvolveLink>
      {showSelected && selectedItem?.id === project?.projectId && (
        <EvolveIcon icon="CheckCircle" color="green" size="sm" />
      )}
    </>
  );
};
