import { forwardRef, useEffect } from 'react';

import { Box, Card, Flex, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { EvolveIcon, EvolveIconName } from 'assets/icons/EvolveIcon';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { EvolveApiReturn, useWrappedGet } from 'hooks-api/useWrappedApiCall';

import type { PartId, PartCategoryId, PartSearchResult, CatalogSearchResult, PartCatalogId } from './types';

const MIN_SEARCH_LENGTH = 2 as const;

type Props = {
  selectedCatalogId: PartCatalogId | null;
  onChange: (item: CatalogSearchResult | PartSearchResult) => void;
  disabled?: boolean;
};

export const CategoryPartSearch = ({ selectedCatalogId, onChange, disabled }: Props) => {
  const [searchPhrase, setSearchPhrase] = useDebouncedState('', 350);

  const {
    apiCall: searchParts,
    data: partsSearchResult,
    loading: loadingSearchParts,
  } = useWrappedGet<EvolveApiReturn<PartSearchResult>>('moab/part/search', { lazy: true });

  const {
    apiCall: searchPartCategories,
    data: categorySearchResult,
    loading: loadingSearchPartCategories,
  } = useWrappedGet<EvolveApiReturn<CatalogSearchResult>>('moab/partCategory/search', { lazy: true });

  useEffect(() => {
    if (searchPhrase.length >= MIN_SEARCH_LENGTH) {
      const opts = { params: { take: 10, searchTerm: searchPhrase, partCatalogId: selectedCatalogId } };
      searchParts(opts);
      searchPartCategories(opts);
    }
  }, [searchPartCategories, searchParts, searchPhrase, selectedCatalogId]);

  return (
    <Box pos="relative">
      <WrappedSelect<PartCategoryId | PartId>
        placeholder="Search..."
        disabled={isNil(selectedCatalogId) || disabled}
        searchable
        filter={() => true}
        rightSection={<EvolveIcon icon={null} />}
        value={null}
        withinPortal
        onChange={(id) => {
          const selectedPartCategory = categorySearchResult?.data.find((c) => c.categoryId === id);
          if (isNotNil(selectedPartCategory)) {
            onChange(selectedPartCategory);
            return;
          }
          const selectedPart = partsSearchResult?.data.find((p) => p.partId === id);
          if (isNotNil(selectedPart)) {
            onChange(selectedPart);
          }
        }}
        nothingFound={
          searchPhrase.length < MIN_SEARCH_LENGTH || loadingSearchPartCategories || loadingSearchParts
            ? 'Start typing...'
            : undefined
        }
        maxDropdownHeight={400}
        styles={{
          wrapper: { minWidth: 100 },
          dropdown: {
            minWidth: 208,
            width: 'auto !important',
          },
        }}
        onSearchChange={setSearchPhrase}
        itemComponent={ItemRenderer}
        data={
          searchPhrase.length >= MIN_SEARCH_LENGTH
            ? [
                ...(categorySearchResult?.data.length === 0 || loadingSearchPartCategories
                  ? [
                      {
                        label: loadingSearchPartCategories ? 'Loading...' : 'No categories found.',
                        value: 'loadingCategories',
                        group: 'Categories',
                        disabled: true,
                      } as const,
                    ]
                  : categorySearchResult?.data
                      .filter((c) => isNotNil(c.parentCategoryPath))
                      .map((c) => ({
                        value: c.categoryId,
                        category: c,
                        group: 'Categories',
                      })) ?? []),
                ...(partsSearchResult?.data.length === 0 || loadingSearchParts
                  ? [
                      {
                        label: loadingSearchParts ? 'Loading...' : 'No parts found.',
                        value: 'loadingParts',
                        group: 'Parts',
                        disabled: true,
                      } as const,
                    ]
                  : partsSearchResult?.data.map((p) => ({
                      value: p.partId,
                      part: p,
                      group: 'Parts',
                    })) ?? []),
              ]
            : []
        }
      />
    </Box>
  );
};

type ItemProps = {
  part?: PartSearchResult;
  category?: CatalogSearchResult;
  label?: string;
};

const ResultRenderer = ({
  label,
  description,
  icon,
}: {
  label: string;
  description?: string | null;
  icon: EvolveIconName;
}) => (
  <Flex gap="xs" maw={500} style={{ overflow: 'hidden' }}>
    <Card p={0} withBorder w={40} h={40} miw={40}>
      <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <EvolveIcon icon={icon} />
      </Flex>
    </Card>
    <Flex direction="column" justify="center">
      <Text lineClamp={1}>{label}</Text>
      <Text lineClamp={2} fz="xs" c="dimmed" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {description?.replaceAll(
          '/',
          // Invisible character to assist in line-breaks
          '\u200B/',
        )}
      </Text>
    </Flex>
  </Flex>
);

const ItemRenderer = forwardRef<HTMLDivElement, ItemProps>(({ part, category, label, ...others }, ref) => (
  <Box ref={ref} fz="sm" {...others}>
    {isNotNil(part) && (
      <ResultRenderer
        label={part.partName}
        description={`Mfg: ${part.manufacturerName}`}
        icon={part.hasAssembly ? 'Assembly' : 'Part'}
      />
    )}
    {isNotNil(category) && (
      <ResultRenderer label={category.categoryName} description={category.parentCategoryPath} icon="Folder" />
    )}
    {isNil(part) && isNil(category) && <span>{label}</span>}
  </Box>
));
