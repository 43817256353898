/* eslint-disable prefer-promise-reject-errors */
import { useCallback } from 'react';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { AuthCreds, AuthReturn } from '../types';
import { useFetchProviderStatus } from './restCalls';

const { STAY_SIGNED_IN } = LOCAL_STORAGE_CONSTANTS;

export const useSignIn = () => {
  const { fetchProviderStatus } = useFetchProviderStatus();
  const { apiCall } = useWrappedPost<AuthReturn, AuthCreds>('admin/authentication');

  const checkForLatestEulaAgreement = useCallback(
    async (email: string) => {
      const res = await fetchProviderStatus(email);
      if ('acceptedLatestEula' in res) {
        const { acceptedLatestEula, userName } = res;
        return { acceptedLatestEula, userName };
      }
      return { acceptedLatestEula: null, userName: null };
    },
    [fetchProviderStatus],
  );

  const signIn = useCallback(
    async ({
      email,
      password,
      keepEnabled,
    }: {
      email: string;
      password: string;
      keepEnabled: boolean;
    }): Promise<AuthReturn> => {
      const provider = await fetchProviderStatus(email);
      const { userStatus } = provider;

      if (userStatus === 'UNCONFIRMED')
        return Promise.reject({
          code: 'UserNotVerifiedFoundException',
          message: 'The email address is not verified',
        });

      if (['EMAIL_NOT_FOUND', 'USER_NOT_FOUND'].includes(userStatus))
        return Promise.reject({
          code: 'UserNotFoundException',
          message: 'The email address does not exist',
        });

      if (userStatus === 'FORCE_CHANGE_PASSWORD')
        return Promise.reject({
          code: 'UserNotFoundException',
          message: 'Username/client id combination not found.',
        });

      // TODO: if user has to complete the invitation link first

      const authResult = await apiCall({ userName: email, password });

      if (keepEnabled) {
        localStorage.setItem(STAY_SIGNED_IN, 'true');
      }

      return authResult;
    },
    [fetchProviderStatus, apiCall],
  );

  return { signIn, checkForLatestEulaAgreement };
};
