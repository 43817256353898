import './forge-container.css';
import type { ReactNode } from 'react';

import { ActionIcon, Box, Flex, Loader, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ForgeViewer } from 'components/Autodesk';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { isNotNil } from 'helpers/isNotNil';
import type { Document } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';

import { useGetGraphicType } from './hooks/useGetGraphicType';

export const ForgeViewerDocument = ({
  document,
  headerContents,
  noDocumentSelected = 'Select a drawing.',
  noHeader = false,
  setCurrentPage,
  setViewerRenderComplete,
}: {
  document?: Document | null;
  headerContents?: ReactNode;
  noHeader?: boolean;
  noDocumentSelected?: ReactNode;
  setCurrentPage?: (page: number) => void;
  setViewerRenderComplete?: (renderComplete: boolean) => void;
}) => {
  const { dynamicProps, isSupported, downloadDocument, presigningInProgress } = useGetGraphicType(document);
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {!noHeader && (
        <Flex px="sm" align="center" className="forge-header" gap="md">
          {headerContents}
          <Text fw={500}>{document?.documentName}</Text>
          {isNotNil(document?.storagePath) && (
            <ActionIcon onClick={downloadDocument} loading={presigningInProgress} size="sm">
              <EvolveIcon icon="Download" size="sm" color="inherit" />
            </ActionIcon>
          )}
        </Flex>
      )}
      {isNotNil(document) ? (
        <>
          {isSupported ? (
            <>
              {isNotNil(dynamicProps?.urn) ? (
                <Box pos="relative" className="forge-container">
                  <ForgeViewer
                    key={document.documentId}
                    setCurrentPage={setCurrentPage}
                    setViewerRenderComplete={setViewerRenderComplete}
                    {...dynamicProps}
                  />
                </Box>
              ) : (
                <Flex align="center" justify="center" style={{ height: '100%' }}>
                  <Loader />
                </Flex>
              )}
            </>
          ) : (
            <Flex align="center" justify="center" style={{ height: '100%' }}>
              <NoRowsOverlay
                label={
                  <Flex ta="center" direction="column" gap={4}>
                    <Text>File type unsupported.</Text>
                    <Text>
                      Click{' '}
                      <Text fw={500} span className="attachment-link" onClick={downloadDocument}>
                        here
                      </Text>{' '}
                      to download.
                    </Text>
                  </Flex>
                }
                icon="DrawingDocument"
              />
            </Flex>
          )}
        </>
      ) : (
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <NoRowsOverlay label={noDocumentSelected} icon="DrawingDocument" />
        </Flex>
      )}
    </Box>
  );
};
