import { useEffect, useState } from 'react';

import { Button } from '@mantine/core';
import { useReactFlow } from '@xyflow/react';

import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { SelectableTask } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/SelectableTask';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

import { useAssemblyEditor } from './useAssemblyEditor';
import { usePartEditor } from './usePartEditor';
import { useCreateNodesFromTasks } from './utils';

const AddTasksButton = ({
  selectedTasks,
  clearSelectedTasks,
  disabled,
}: {
  selectedTasks: TaskType[];
  clearSelectedTasks: () => void;
  disabled?: boolean;
}) => {
  const { createNodesFromTasks } = useCreateNodesFromTasks();
  const { addNode } = useAssemblyEditor();
  const { fitView, getZoom } = useReactFlow();

  const addSelectedTasks = () => {
    createNodesFromTasks(selectedTasks).map(addNode);
    setTimeout(() => {
      fitView({
        duration: 300,
        maxZoom: getZoom(),
        padding: 0,
      });
    }, 250);
    clearSelectedTasks();
  };
  return (
    <Button onClick={addSelectedTasks} disabled={selectedTasks.length === 0 || disabled}>
      Add to assembly
    </Button>
  );
};

export const AddTaskToAssembly = () => {
  const { companyId, publishing } = usePartEditor();

  const [selectedTasks, setSelectedTasks] = useState<TaskType[]>([]);
  const { requestDocumentDetails } = useDocumentsCache();
  const { data, ...paginatedGet } = useWrappedPaginatedGet<TaskType>('shop/taskType', {
    lazy: true,
    defaultConfig: {
      params: {
        orderBy: 'taskTypeName',
        companyId,
      },
    },
  });
  useEffect(() => {
    requestDocumentDetails(data.map((d) => d.taskTypeImageId).filter(isNotNil));
  }, [data, requestDocumentDetails]);

  return (
    <SearchableCheckList
      paginatedGet={{ data, ...paginatedGet }}
      idKey="taskTypeId"
      disabled={publishing}
      onChange={setSelectedTasks}
      value={selectedTasks}
      rightSideComponent={
        <AddTasksButton
          disabled={publishing}
          selectedTasks={selectedTasks}
          clearSelectedTasks={() => setSelectedTasks([])}
        />
      }
      textInputProps={{ placeholder: 'Search tasks...', style: { width: '90%', maxWidth: 400 } }}
      flexListProps={{ pb: 'sm', style: { flex: '1 0 0' } }}
      getItemLabel={(task) => <SelectableTask task={task} />}
    />
  );
};
