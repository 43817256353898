import { Stack, StackProps, Title } from '@mantine/core';

import { Custom } from 'components/Icons';

const MobileRestricted = () => (
  <StyledRestrictedBackground>
    <Custom.MobileRestricted />
    <Title order={3}>Switch to a larger display to view content.</Title>
  </StyledRestrictedBackground>
);

const StyledRestrictedBackground = (props: StackProps) => (
  <Stack
    style={{
      width: '100%',
      height: '91vh',
      justifyContent: 'center',
      color: 'rgba(0,0,0,0.5)',
      alignItems: 'center',
      marginLeft: '-64px',
    }}
    {...props}
  />
);

export default MobileRestricted;
