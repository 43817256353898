import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Anchor, Card, Flex, Indicator, Loader, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { format, parseISO } from 'date-fns';

import { isNotNil } from 'helpers/isNotNil';

import { useMessages } from './MessagesContext';
import type { Message, MessageId } from './types';

type Props = {
  message: Message;
  currentlyViewing: MessageId | null;
  setCurrentlyViewing: (messageId: MessageId | null) => void;
  canArchive: boolean;
  refreshAllMessages: () => Promise<void>;
};

export const MessagePreview = ({
  message,
  currentlyViewing,
  setCurrentlyViewing,
  canArchive,
  refreshAllMessages,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { updateMessage, deleteMessage } = useMessages();
  const [saving, setSaving] = useState(false);
  const [read, setRead] = useState(isNotNil(message.accessedOn));

  const selected = useMemo(() => currentlyViewing === message.messageId, [currentlyViewing, message.messageId]);

  useEffect(() => {
    if (selected) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [selected]);

  useEffect(() => {
    if (selected && !read) {
      updateMessage(message.messageId, 'Read').then(() => {
        setRead(true);
      });
    }
  }, [selected, read, message.messageId, updateMessage]);

  const doDelete = useCallback(() => {
    setSaving(true);
    deleteMessage(message.messageId)
      .then(() => {
        notifications.show({
          title: 'Deleted',
          message: 'Message successfully deleted',
          color: 'green',
        });
        refreshAllMessages();
        setCurrentlyViewing(null);
      })
      .catch(() => setSaving(false));
  }, [deleteMessage, message.messageId, refreshAllMessages, setCurrentlyViewing]);

  const doArchive = useCallback(() => {
    setSaving(true);
    updateMessage(message.messageId, 'Archive')
      .then(() => {
        notifications.show({
          title: 'Archived',
          message: 'Message successfully archived',
          color: 'green',
        });
        refreshAllMessages();
        setCurrentlyViewing(null);
      })
      .catch(() => setSaving(false));
  }, [message.messageId, refreshAllMessages, setCurrentlyViewing, updateMessage]);

  return (
    <Indicator disabled={read} position="middle-start" size={16} key={message.messageId}>
      <Card
        ref={ref}
        shadow="sm"
        radius="sm"
        withBorder
        bg={selected ? 'gray.3' : undefined}
        style={{
          flex: '0 0 auto',
          userSelect: 'none',
          ...(selected
            ? {
                borderColor: 'gray',
              }
            : {}),
        }}
        onClick={() => {
          setCurrentlyViewing(message.messageId);
        }}
      >
        <Text>{message.subject}</Text>
        <Text size="sm" color="gray">
          {format(parseISO(`${message.createdOn}Z`), 'MMMM dd, yyyy')}
        </Text>

        {selected && (
          <>
            {saving ? (
              <Flex justify="right">
                <Loader size="sm" />
              </Flex>
            ) : (
              <Flex gap="md" justify="right">
                {canArchive && <Anchor onClick={doArchive}>Archive</Anchor>}
                <Anchor onClick={doDelete}>Delete</Anchor>
              </Flex>
            )}
          </>
        )}
      </Card>
    </Indicator>
  );
};
