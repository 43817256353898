import './assembly.css';
import type { CSSProperties, ReactNode } from 'react';

import { Card, Flex, Text, useMantineTheme } from '@mantine/core';
import { Handle, Position, Node, NodeProps, IsValidConnection, useReactFlow } from '@xyflow/react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import type { WithOptional } from 'helpers/helper-types';
import type { TaskTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { EDGE_TYPE_ID_DICT, HANDLE_POSITION_ID } from 'modules/Materials/AssemblyEditor/Utils/constants';
import { DocumentImage } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/useDocumentImage';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';

import type { AssemblyNodePart } from '../types';
import { maxPartNodeWidth, maxTaskNodeWidth, partNodeHeight, taskNodeHeight } from './constants';
import { EditNode } from './EditNode';

export type PartNodeType = Node<{
  part: AssemblyNodePart;
  quantity: number;
}>;

export type TaskNodeType = Node<{
  taskType: {
    taskTypeId: TaskTypeId;
    taskTypeName: string;
    taskTypeDescription?: string | null;
    documentId?: DocumentId;
  };
}>;

export type AssemblyNodeType = Node<{
  part: WithOptional<AssemblyNodePart, 'partId'>;
}>;

export type NodeType = PartNodeType | TaskNodeType | AssemblyNodeType;

const handleMargin = 12;
const handleSize = 10;

const handleStyle = (position: Position): CSSProperties => ({
  width: handleSize,
  height: handleSize,
  backgroundColor: 'white',
  border: '1px solid black',
  left: position === Position.Left ? handleMargin - handleSize / 2 : undefined,
  right: position === Position.Right ? handleMargin - handleSize / 2 : undefined,
  top: position === Position.Top ? handleMargin - handleSize / 2 : undefined,
  bottom: position === Position.Bottom ? handleMargin - handleSize / 2 : undefined,
});

const labelStyle = (position: Position): CSSProperties | undefined => {
  if (position === Position.Top) {
    return {
      cursor: 'initial',
      position: 'absolute',
      top: -handleSize * 2.5,
      left: -13,
    };
  }
  if (position === Position.Bottom) {
    return {
      cursor: 'initial',
      position: 'absolute',
      bottom: -handleSize * 2.5,
      left: -16,
    };
  }
  if (position === Position.Left) {
    return {
      cursor: 'initial',
      position: 'absolute',
      left: -42,
      top: -7,
    };
  }
  if (position === Position.Right) {
    return {
      cursor: 'initial',
      position: 'absolute',
      right: -49,
      top: -7,
    };
  }
  return undefined;
};

const WrappedHandle = ({
  id,
  position,
  isValidConnection,
  showLabels,
}: {
  id: HANDLE_POSITION_ID;
  position: Position;
  isValidConnection: IsValidConnection;
  showLabels?: boolean;
}) => (
  <Handle
    className="hover-node--on"
    type={position === Position.Top || position === Position.Left ? 'target' : 'source'}
    position={position}
    id={id}
    style={handleStyle(position)}
    isValidConnection={isValidConnection}
  >
    {showLabels && (
      <Card px={4} py={1} radius="sm" bg="rgba(255,255,255,0.6)" style={labelStyle(position)}>
        <Text fz="sm">{position === Position.Top || position === Position.Left ? 'Start' : 'Finish'}</Text>
      </Card>
    )}
  </Handle>
);

const Handles = ({ showLabels = false, children }: { children: ReactNode; showLabels?: boolean }) => {
  const { getEdges } = useReactFlow();

  const isValidConnection: IsValidConnection = (params) => {
    if (params.source === params.target) return false;
    // Prevent multiple edges from the same source and the same target
    const connectionExists = getEdges().some((e) => e.source === params.source && e.target === params.target);
    return !connectionExists;
  };
  return (
    <div className="hover-node" style={{ padding: handleMargin }}>
      {children}
      <WrappedHandle
        id={EDGE_TYPE_ID_DICT.c}
        position={Position.Top}
        isValidConnection={isValidConnection}
        showLabels={showLabels}
      />
      <WrappedHandle
        id={EDGE_TYPE_ID_DICT.a}
        position={Position.Left}
        isValidConnection={isValidConnection}
        showLabels={showLabels}
      />
      <WrappedHandle
        id={EDGE_TYPE_ID_DICT.b}
        position={Position.Right}
        isValidConnection={isValidConnection}
        showLabels={showLabels}
      />
      <WrappedHandle
        id={EDGE_TYPE_ID_DICT.d}
        position={Position.Bottom}
        isValidConnection={isValidConnection}
        showLabels={showLabels}
      />
    </div>
  );
};

export const PartNode = (node: NodeProps<PartNodeType | AssemblyNodeType>) => {
  const {
    type,
    data: { part, ...data },
  } = node;
  const theme = useMantineTheme();
  const colors = type === 'part' ? theme.colors.gray : theme.colors.orange;
  return (
    <Handles>
      <Card
        p={0}
        withBorder
        bg={colors[0]}
        style={{
          height: partNodeHeight,
          minWidth: 250,
          maxWidth: maxPartNodeWidth,
          borderColor: colors[6],
          boxSizing: 'border-box',
        }}
      >
        <Flex>
          <Flex
            bg="gray.2"
            style={{ height: partNodeHeight, width: partNodeHeight, minWidth: partNodeHeight }}
            align="center"
            justify="center"
          >
            <DocumentImage
              documentId={part.documentIds?.[0]}
              alt={part.partName}
              fallback={<EvolveIcon size="xl" icon={type === 'assembly' ? 'Assembly' : 'Part'} />}
            />
          </Flex>
          <Flex direction="column" justify="space-between" style={{ flex: '1 1 auto' }}>
            <Flex direction="column" px="sm" justify="center" fz="sm" style={{ flex: '1 1 auto' }}>
              <Text lineClamp={1} title={part.partName} fw={500}>
                {part.partName}
              </Text>
              {!!part.description && (
                <Text lineClamp={1} fz="xs" title={part.description}>
                  {part.description}
                </Text>
              )}
              {type === 'part' && <EditNode node={node as NodeProps<PartNodeType>} />}
            </Flex>
            <Flex bg="gray.1" gap="sm" fz="xs" px="sm" py={4} c="dimmed">
              {type === 'part' && 'quantity' in data && <Text>Unit Qty: {data.quantity}</Text>}
              <Text>UOM: {part.unitOfMeasureCode}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Handles>
  );
};

export const TaskNode = ({
  data: {
    taskType: { taskTypeName, taskTypeDescription, documentId },
  },
}: NodeProps<TaskNodeType>) => {
  const theme = useMantineTheme();
  return (
    <Handles showLabels>
      <Card
        withBorder
        bg="violet.1"
        p={0}
        style={{
          height: taskNodeHeight,
          minWidth: 200,
          maxWidth: maxTaskNodeWidth,
          borderColor: theme.colors.violet[6],
          boxSizing: 'border-box',
        }}
      >
        <Flex>
          <Flex
            bg="white"
            align="center"
            justify="center"
            style={{ width: taskNodeHeight, height: taskNodeHeight, minWidth: taskNodeHeight }}
          >
            <DocumentImage
              documentId={documentId}
              alt={taskTypeName}
              fallback={<EvolveIcon icon="WorkCellTask" color="violet.8" />}
            />
          </Flex>
          <Flex px="sm" direction="column" justify="space-evenly" style={{ overflow: 'hidden', userSelect: 'none' }}>
            <AgGridStyleTooltip label={taskTypeName} position="top-start">
              <TextWithRef fz="sm" c="violet.8">
                {taskTypeName}
              </TextWithRef>
            </AgGridStyleTooltip>
            {!!taskTypeDescription && (
              <AgGridStyleTooltip label={taskTypeDescription} position="top-start">
                <TextWithRef fz="xs" c="violet.9">
                  {taskTypeDescription}
                </TextWithRef>
              </AgGridStyleTooltip>
            )}
          </Flex>
        </Flex>
      </Card>
    </Handles>
  );
};
