import { ActionIcon, Indicator } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { useMessages } from 'app/MessageCenter/MessagesContext';
import { Custom } from 'components/Icons';

export const MailButton = () => {
  const navigate = useNavigate();
  const goToMessageCenter = () => navigate('/message');
  const { totalUnread } = useMessages();

  return (
    <Indicator
      color="red"
      inline
      label={totalUnread > 999 ? '999+' : totalUnread}
      disabled={totalUnread === 0}
      size={16}
    >
      <ActionIcon variant="filled" size="lg" onClick={goToMessageCenter} radius="xl">
        <Custom.Mail />
      </ActionIcon>
    </Indicator>
  );
};
