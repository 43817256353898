import { ReactNode, useCallback, useEffect, useState } from 'react';

import { Flex, Loader, Overlay, Text } from '@mantine/core';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { useLocalStorage } from 'hooks/useLocalStorage';
import type { CompanyId } from 'types/types-api';

import { CatalogBreadcrumb, NavPartCategory } from './CatalogBreadcrumb';
import { CatalogSelect } from './CatalogSelect';
import { CategoryCard } from './CategoryCard';
import { CategoryPartSearch } from './CategoryPartSearch';
import type { PartCategory, PartListingProps, PartSearchResult, CatalogSearchResult } from './types';

type Props = {
  companyId: CompanyId;
  /** If `false`, will not update the selected catalog in local storage @default true */
  useLocalStorageCatalog?: boolean;
  refresh?: () => void;
  rightSideComponent?: ReactNode;
  children: (props: PartListingProps) => ReactNode;
  disabled?: boolean;
};

export const Catalog = ({
  rightSideComponent,
  disabled,
  children,
  companyId,
  refresh,
  useLocalStorageCatalog,
}: Props) => {
  const [selectedCatalogId, setSelectedCatalogId] = useLocalStorage<'SELECTED_CATALOG_ID'>(
    LOCAL_STORAGE_CONSTANTS.SELECTED_CATALOG_ID,
    null,
    useLocalStorageCatalog,
  );
  const [selectedCategory, setSelectedCategory] = useState<NavPartCategory | null>(null);
  const [categoryHistory, setCategoryHistory] = useState<NavPartCategory[]>([]);

  const [selectedSearchItem, setSelectedSearchItem] = useState<CatalogSearchResult | PartSearchResult | null>();
  const partFromSearch = isNotNil(selectedSearchItem) && 'partId' in selectedSearchItem ? selectedSearchItem : null;
  const categoryFromSearch =
    isNotNil(selectedSearchItem) && 'categoryId' in selectedSearchItem ? selectedSearchItem : null;

  const {
    data: categories,
    setDefaultOpts: setDefaultOptsCategory,
    loading: loadingCategories,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', { lazy: true });

  useEffect(() => {
    setSelectedCategory((currentCategory) => {
      const category = isNil(currentCategory) ? categories[0] : currentCategory;
      if (isNotNil(category) && isNil(currentCategory)) {
        setCategoryHistory([category]);
      }
      return category;
    });
  }, [categories]);

  useEffect(() => {
    if (isNotNil(selectedCatalogId)) {
      setDefaultOptsCategory({
        lazy: false,
        defaultConfig: {
          params: {
            parentPartCategoryId: selectedCategory?.partCategoryId ?? '',
            partCatalogId: selectedCatalogId,
            orderBy: 'name:asc',
            companyId,
          },
        },
      });
    }
  }, [selectedCatalogId, selectedCategory, companyId, setDefaultOptsCategory]);

  const resetToFirstCategory = useCallback(() => {
    setSelectedSearchItem(null);
    setCategoryHistory(([baseCategory]) => {
      setSelectedCategory(baseCategory);
      return [baseCategory];
    });
  }, []);

  const disableCatalogNav = disabled || isNotNil(partFromSearch);

  return (
    <>
      <Flex justify="space-between" gap="sm" wrap="wrap">
        <Flex gap="sm" style={{ flex: '1 0 180px' }}>
          <CatalogSelect
            selectedCatalogId={selectedCatalogId}
            onCatalogSelected={(id) => {
              setCategoryHistory([]);
              setSelectedCatalogId(id);
              setSelectedCategory(null);
              setSelectedSearchItem(null);
            }}
            companyId={companyId}
            disabled={disableCatalogNav}
          />
          <CategoryPartSearch
            selectedCatalogId={selectedCatalogId}
            disabled={disableCatalogNav}
            onChange={(item) => {
              if (isNotNil(categoryFromSearch)) {
                resetToFirstCategory();
              }
              setSelectedSearchItem(item);
              if (!('partId' in item)) {
                const newCategory: NavPartCategory = {
                  partCategoryId: item.categoryId,
                  partCategoryName: item.categoryName,
                };
                setCategoryHistory((hist) => [hist[0], newCategory]);
                setSelectedCategory(newCategory);
              }
            }}
          />
        </Flex>
        {rightSideComponent}
      </Flex>

      <Flex direction="column" mt="sm" style={{ flex: '1 0 0', overflowY: 'auto', position: 'relative' }}>
        <CatalogBreadcrumb
          categoryHistory={categoryHistory}
          setCategoryHistory={setCategoryHistory}
          setSelectedCategory={setSelectedCategory}
          blur={disableCatalogNav}
          categoryIsFromSearch={isNotNil(categoryFromSearch)}
          clearSearchCategory={resetToFirstCategory}
        />

        <Flex gap="md" mt="sm" wrap="wrap" pos="relative">
          {isNil(selectedCatalogId) && !loadingCategories && (
            <Text c="dimmed" ml="md" fz="sm">
              Select a catalog to get started.
            </Text>
          )}
          {disableCatalogNav && <Overlay color="#fff" blur={2} />}
          {!loadingCategories && categories.length === 0 && isNotNil(selectedCategory) && (
            <Text c="dimmed" m="md" fz="sm">
              No sub categories found.
            </Text>
          )}
          {loadingCategories && <Loader m="xs" />}
          {categories.map((c) => (
            <CategoryCard
              key={c.partCategoryId}
              category={c}
              onClick={() => {
                setSelectedCategory(c);
                setCategoryHistory((h) => [...h, c]);
              }}
            />
          ))}
        </Flex>
        {children({
          partCatalogId: selectedCatalogId,
          partCategoryId: selectedCategory?.partCategoryId,
          overridePartName: partFromSearch?.partName,
          goBack: () => setSelectedSearchItem(null),
          refresh,
        })}
      </Flex>
    </>
  );
};
